<template>
  <v-list :opened="opened" @update:opened="newOpened => opened = newOpened" max-width="320" @click.stop.prevent.self>
    <v-list-group
      v-bind:disabled="state.searchTerm !== '' ? true : false"
      v-if="!isGuest && !mini"
      active-class="listColor"
      :expand-icon="
        sortedUsers.length === 0 || state.showSearch ? null : '$expand'
      "
      @click.stop.prevent="handlerClickUsers($event)"
      value="users"
    >
      <template v-slot:activator="{ props }">
        <v-list-item v-bind="props"> 
          <!-- @click.stop.prevent="handlerClickUsers($event, 2)" -->
          <template v-slot:prepend>
            <v-icon size="18" :class="{ iconWithPL: mini }">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <font-awesome-icon
                    v-bind="props"
                    @click.stop.prevent="handlerClickUserIcon"
                    class="primary--text-sidepanel cursorPointer"
                    :icon="
                      currentPage('/users') ? ['fas', 'user'] : ['fal', 'user']
                    "
                    :class="`${getRoute === '/users' ? 'primary--text-sidepanel' : ''}`"
                  />
                </template>
                <span>{{ $t("components.sidebar.userMiniTT") }}</span>
              </v-tooltip>
            </v-icon>
          </template>
          <v-list-item-title
            v-if="!mini"
            class="cursorPointer w100"
            @click.stop.prevent="showHideSearch"
          >
            <span v-if="!state.showSearch" class="primary--text-sidepanel">
              {{ $t("generics.searchUser") }} <br />
              <hr class="hrUSers"
            /></span>
            <DrawerContentSearch v-if="!amIGuest && state.showSearch" />
            <v-tooltip
              top
              v-if="!isMobile && amIAdmin && !state.showSearch && !mini"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon
                  variant="text"
                  density="compact"
                  class="btnAddGroup"
                  v-bind:disabled="false"
                  @click.stop.prevent="openInviteUser"
                >
                  <font-awesome-icon
                    class="primary--text-sidepanel"
                    :icon="['fal', 'plus-circle']"
                    :style="{ fontSize: '20px' }"
                  />
                </v-btn>
              </template>
              <span>{{ $t("components.sidebar.userTT") }}</span>
            </v-tooltip>
          </v-list-item-title>
        <CustomTT
          v-if="showCustomTT && !mini"
          :text="`${$t('components.hotkeys.controlKey')} + S`"
          position="bottom"
          :extraclass="'ctrs'"
        />
        </v-list-item>
      </template>
      <UserList v-if="openedList && !state.showSearch" :persons="sortedUsers" :section="'Favorites'" />
    </v-list-group>
    <v-tooltip right v-if="!isGuest && mini">
      <template v-slot:activator="{ props }">
        <v-list-item  v-bind="props" class="cursorPointer">
          <v-icon size="16"
            :class="{ iconWithPL: mini }"
            @click.stop.prevent="handlerClickUserIcon"
          >
            <font-awesome-icon
              class="primary--text-sidepanel cursorPointer"
              :icon="currentPage('/users') ? ['fas', 'user'] : ['fal', 'user']"
              :class="`${getRoute === '/users' ? 'primary--text-sidepanel' : ''}`"
            />
          </v-icon>
        </v-list-item>
      </template>
      <span>{{ $t("components.sidebar.userMiniTT") }}</span>
    </v-tooltip>
  </v-list>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../store";
import UserList from "./userList.vue";
import InviteGuest from "../navbar/inviteGuest.vue";
import DrawerContentSearch from "./drawerContentSearch.vue";
import { isMobile, isIpad } from "../../lib/mobileUtil";
import CustomTT from "../content/customTT.vue";
import { hotkeysInfoStore } from "../../effector/hotkeysInfo";
import { isConferenceCall } from "../../utils/calls";
import { checkListSort } from "../../utils/basicFunctions";
import { hasPrivilege } from "../../utils/privileges";

export default {
  props: ["mini", "openSidebar", "setList", "isGuest", "active"],
  components: {
    UserList,
    DrawerContentSearch,
    InviteGuest,
    CustomTT,
  },
  data() {
    const effector = {
      showCustomTT: hotkeysInfoStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return{
      state: store.state,
      isIpad: isIpad(),
      isMobile: isMobile(),
      openedList: false,
      opened: [],
      // Effector
      ...effector,
    }
  },
  watch: {
    "isActive": {
      handler(value) {
        if(!value){
          this.opened = [];
        }
      },
    },
    mini() {
      if (this._debounceOpener) clearTimeout(this._debounceOpener);
      if (this.mini || this.opened?.length) {
        this.openedList = !this.mini || this.opened?.length;
      } else {
        this._debounceOpener = setTimeout(() => {
          this.openedList = !this.mini || this.opened?.length;
        }, 1200);
      }
    },
    "opened": {
      immediate: true,
      handler() {
        if (this._debounceOpener) clearTimeout(this._debounceOpener);
        this.openedList = !this.mini || this.opened?.length;
      },
    },
    'state.searchTerm':{
      handler(value) {
        if(value.length > 2){
          this.opened = [];
        }
      },
    }
  },
  mounted() {
    EventBus.$on("focusSearchFieldEvent", this.showHideSearch);
  },
  unmounted(){
    EventBus.$off("focusSearchFieldEvent", this.showHideSearch);
  },
  methods: {
    handlerClickUsers(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.setList("users");
    },
    openInviteUser() {
      this.setCurrentContentVisile("invitationformuser", true, this.$router);
    },
    currentPage(page) {
      const actualRoute = this.$route.path;
      if (actualRoute === page) {
        return true;
      }
      return false;
    },
    handlerClickUserIcon() {
      if (!this.mini) {
        if (this.canGoToUserPage) this.gotoPage("users");
      } else {
        this.openSidebar();
      }
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    gotoPage(route) {
      this.setCurrentContentVisile(route, true, this.$router);
    },
    showHideSearch() {
      if (!this.state.showSearch) {
        this.state.showSearch = !this.state.showSearch;
      }
    },
  },
  computed: {
    isActive() {
      return this.active == "users";
    },
    sortedUsers() {
      let persons = [];
      for (let uuid in store.state.group) {
        if (uuid) {
          const person = store.state.group[uuid];
          if (!person || !person.user || !person.user.email || !hasPrivilege(uuid)) {
            continue;
          } else {
            persons.push({ ...person, uuid });
          }
        }
      }
      return checkListSort(persons, this.state.user.userSettings.usersSort);
    },
    isConference() {
      if (Object.keys(store.state.remoteBridgeStreams)[0])
        return isConferenceCall(Object.keys(store.state.remoteBridgeStreams));
    },
    canGoToUserPage() {
      if (this.amIAdmin || this.state.namespaceSettings.displayAllUsers) {
        return true;
      } else {
        return false;
      }
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.state.ownUUID);
    },
    amIGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    getRoute() {
      return this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
.reserveSpace {
  display: contents;
}
.hrUSers {
  // border-color: #8ec1cb;
  width: 80%;
}
.cursorPointer {
  cursor: pointer;
}
.v-list {
  padding: 0;
}
.v-list-item__icon {
  margin-right: 8px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0;
}
.v-list-item .v-list-item__title {
  line-height: 2.5 !important;
}
.v-theme--dark .listColor {
  color: white !important;
}
.v-theme--light .listColor {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>

<style lang="scss">
.v-list-item__prepend {
  width: 32px;
}
.usersListItemNav {
  .v-list-item__icon .v-list-group__header__append-icon {
    min-width: 35px !important;
  }
}
.ctrs {
  top: 50px !important;
  left: 15px;
}
.iconsSize20{
  width: 20px !important;
  height: 20px !important;
}
</style>