<template>
  <div style="padding: 2px">
    <v-tooltip location="top">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          icon
          color="#0000008a"
          class="buttonCallFrontCard"
          @click.stop.prevent="sendMessage()"
        >
          <img src="/img/icon_icon.svg" style="width: 20px" />
        </v-btn>
      </template>
      <span>{{ $t("components.userListItem.callBackTT") }}</span>
    </v-tooltip>
  </div>
</template>
<script>
import store from "../../store";
import { newMessageEvent } from "../../effector/message";
export default {
  components: {},
  props: ["person"],
  data() {
    return {
      state: store.state,
    };
  },
  methods: {
    sendMessage() {
      const text = this.state.user.name +' '+ this.$t("components.userListItem.callBackMessage");
      this.preparedataToSendMsgEffector(this.person.uuid, text);
    },
    preparedataToSendMsgEffector(id, text) {
      const userToSendMsg = {};
      userToSendMsg[id] = {
        userUUID: id,
        read: false,
        replied: false,
        deleted: false,
        viewed: false,
      };

      const dataMsg = {
        body: text,
        header: "individual message",
        date: Date.now(),
        users: userToSendMsg,
        isReply: false,
        creatorUUID: this.ownUUID,
        masterMessageUUID: null,
        parentMessageUUID: null,
        type: "message",
      };
      newMessageEvent(dataMsg);
    },
  },
  computed: {
    additionalStatusColor() {
      return "grey";
    },
  },
};
</script>
<style scoped lang="scss">
.buttonCallFrontCard {
  background: white;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  font-size: 0px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
</style>