<template>
  <div v-click-outside="onClose" style="height:100%">
    <!-- Lateral Panel -->
    <CallLateralPanel
      :isConferenceCall="isConferenceCall"
      :waitingRoomFor="waitingRoomFor"
      :showLateralPanel="showLateralPanel"
      :dataType="dataType"
      :handlerShowLateralPanel="handlerShowLateralPanel"
      :removeParticipantPerson="removeParticipantPerson"
      :showTimeLine="showTimeLine"
    />
    <!-- end lateral panel -->

    <!-- SIDEBAR -->
    <div v-if="maximizeScreen" class="d-flex flex-column w55 sidebarCall">
      <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            density="comfortable"
            variant="text"
            @click.stop.prevent="goToDefaultPage()"
            v-bind="props"
            class="mx-auto mt-2 mb-2"
          >
            <font-awesome-icon
              :icon="['fal', 'home']"
              :style="{ fontSize: '16px' }"
              class="color-white"
            />
          </v-btn>
        </template>
        <span>{{ $t("literals.Home") }}</span>
      </v-tooltip>

      <!-- <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-btn
            ref="buttonMessages"
            icon
            density="comfortable"
            variant="text"
            @click="
              showLateralPanel && dataType == 'messages'
                ? handlerShowLateralPanel(false, null)
                : handlerShowLateralPanel(true, 'messages')
            "
            v-bind="props"
            class="mx-auto mt-2 mb-2"
            :class="{
              darkGreyIcon: showLateralPanel && dataType === 'messages',
            }"
          >
            <font-awesome-icon
              :icon="['fal', 'paper-plane']"
              :style="{ fontSize: '16px' }"
              class="color-white"
            />
            <v-badge
              v-if="totalMessagesNotification > 0"
              right
              color="error"
              :content="totalMessagesNotification"
              :model-value="!!totalMessagesNotification"
              bordered
              overlap
              offset-x="-10"
              offset-y="-5"
              class="waitingRoomBadge"
            ></v-badge>
          </v-btn>
        </template>
        <span>{{ $t("components.drawerContentCard.messages") }}</span>
      </v-tooltip> -->
       <!-- <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            density="comfortable"
            variant="text"
            v-bind="props"
            @click="
              showLateralPanel && dataType == 'favorites'
                ? handlerShowLateralPanel(false, null)
                : handlerShowLateralPanel(true, 'favorites')
            "
            class="mx-auto mt-2 mb-2"
            :class="{
              darkGreyIcon: showLateralPanel && dataType === 'favorites',
            }"
          >
          <v-icon  class="primary--text-sidepanel"> mdi-star-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("components.sidebar.favoritesMiniTT") }}</span>
      </v-tooltip> -->
      <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            density="comfortable"
            variant="text"
            v-bind="props"
            ref="buttonUsers"
            @click="gotoPage('/users')"
            class="mx-auto mt-2 mb-2"
            :class="{ darkGreyIcon: showLateralPanel && dataType === 'users' }"
          >
            <font-awesome-icon
              :icon="['fal', 'user-plus']"
              :style="{ fontSize: '16px' }"
              class="color-white"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.sidebar.userMiniTT") }}</span>
      </v-tooltip>
      <template v-if="getAmIModerator && hasPrivilege(ownUUID)">
        <v-tooltip right>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mx-auto mt-2 mb-2"
              icon
              density="comfortable"
              variant="text"
              @click="openModalSettings()"
            >
              <font-awesome-icon
              :icon="['fal', 'bolt']"
              :style="{ fontSize: '20px' }"
              class="color-white"
            />
            </v-btn>
          </template>
          <span>{{ $t("components.callsContent.addParticipants") }}</span>
        </v-tooltip>
      </template>
      <!-- <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            icon
            density="comfortable"
            variant="text"
            @click="handlerOpenVisitor()"
            v-if="showWaitingRoom && hasPrivilege(ownUUID)"
            class="mx-auto mt-2 mb-2"
            :class="{ darkGreyIcon: showLateralPanel && dataType === 'myWR' }"
          >
            <v-img
              contain
              max-height="28x"
              max-width="25px"
              src="img/icons/guest-plus-white.svg"
              lazy-src="img/icons/guest-plus-white.svg"
              width="23px"
              height="23px"
              style="margin-left: 5px"
            ></v-img>

            <v-badge
              v-if="getconnectedWaitingrooms > 0"
              right
              color="error"
              :content="getconnectedWaitingrooms"
              :model-value="!!getconnectedWaitingrooms"
              bordered
              overlap
              offset-x="7"
              offset-y="7"
              class="waitingRoomBadge"
            ></v-badge>
          </v-btn>
        </template>
        <span>{{ $t("components.sidebar.waitingRoomMiniTT") }}</span>
      </v-tooltip> -->
      <!-- <template v-if="showWaitingRoom && assistantsUser">
        <v-tooltip right v-for="uuid in assistantsUser" :key="uuid">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mx-auto mt-2 mb-2"
              @click="handlerOpenVisitor()"
              icon
              density="comfortable"
              variant="text"
              :class="{
                darkGreyIcon: showLateralPanel && dataType === 'wrFor',
              }"
            >
              <v-img
                contain
                max-height="28x"
                max-width="25px"
                src="img/icons/guest-plus-white.svg"
                lazy-src="img/icons/guest-plus-white.svg"
                width="23px"
                height="23px"
                style="margin-left: 5px"
              ></v-img>
            </v-btn>
          </template>
          <span
            >{{
              $t("components.sidebarCall.waitingRoomFor", [
                getTitelForUuid(uuid),
                getNameForUuid(uuid),
              ])
            }}
          </span>
        </v-tooltip>
      </template> -->
      <!-- <template v-if="hasPrivilege(ownUUID)">
        <v-tooltip right>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mx-auto mt-2 mb-2"
              icon
              density="comfortable"
              variant="text"
              @click="gotoPage('/newconference')"
            >
              <font-awesome-icon
                :icon="['fal', 'users']"
                :style="{ fontSize: '16px' }"
                class="color-white"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.groups.konferenzen") }}</span>
        </v-tooltip>
      </template> -->
      <!-- <template v-if="showOrganigram && hasPrivilege(ownUUID)">
        <v-tooltip right>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mx-auto mt-2 mb-2"
              icon
              density="comfortable"
              variant="text"
              @click="gotoPage('/organisation')"
            >
              <font-awesome-icon
                :icon="['fal', 'sitemap']"
                :style="{ fontSize: '16px' }"
                class="color-white"
              />
            </v-btn>
          </template>
          <span>{{ $t("generics.organisation") }}</span>
        </v-tooltip>
      </template> -->
      <!-- <template v-if="state.namespaceSettings.showCoffeeBreak && hasPrivilege(ownUUID)">
        <v-tooltip right>
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              class="mx-auto mt-2 mb-2"
              icon
              density="comfortable"
              variant="text"
              @click="gotoPage('/coffee-break')"
            >
              <font-awesome-icon
                :icon="['fal', 'mug']"
                :style="{ fontSize: '16px' }"
                class="color-white"
              />
              <v-badge
                v-if="getConnectedCoffeeBreak > 0"
                right
                color="error"
                :content="getConnectedCoffeeBreak"
                :model-value="!!getConnectedCoffeeBreak"
                bordered
                overlap
                offset-x="7"
                offset-y="7"
                class="waitingRoomBadge"
              ></v-badge>
            </v-btn>
          </template>
          <span>{{ $t("status.Coffee break") }}</span>
        </v-tooltip>
      </template> -->
      <!-- <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            class="mx-auto mt-2 mb-2"
            icon
            density="comfortable"
            variant="text"
            @click="gotoPage('/new-room')"
          >
            <font-awesome-icon
              :icon="['fal', 'comment']"
              :style="{ fontSize: '16px' }"
              class="color-white"
            />
          </v-btn>
        </template>
        <span>{{ $t("components.sambaRooms.callRooms") }}</span>
      </v-tooltip> -->
      <!-- START SUPPORT ICONS -->
      <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-btn
            icon
            density="comfortable"
            variant="text"
            v-on="on"
            class="mx-auto mt-2 mb-2"
            @click="openBugModal()"
          >
            <font-awesome-icon
              v-bind="props"
              :icon="['fal', 'headset']"
              :style="{ fontSize: '16px' }"
              class="color-white"
            />
          </v-btn>
        </template>
        <span>{{ $t("generics.support") }}</span>
      </v-tooltip>
       <!-- START askMe ICONS -->
      <div class="askmeContainerCall" v-if="state.namespaceSettings.showGPT4BOT">
        <v-tooltip right>
          <template v-slot:activator="{ props }">
            <v-btn
              icon
              density="comfortable"
              variant="text"
              v-on="on"
              class="mx-auto mt-2 mb-2 w100"
              @click="openGpt4bot()"
            >
              <font-awesome-icon
                v-bind="props"
                :icon="['fal', 'comments']"
                :style="{ fontSize: '16px' }"
                class="color-white"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.sidebar.askYourQuestion") }}</span>
        </v-tooltip>
      </div>

      <AddParticipantsCall
        :closeModal="closeModalSettings"
        :showModalSettings="showModalSettings"
        :instaLink="instaLink"
        :handlerOpenVisitor="handlerOpenVisitor"
        :openUsers="openUsers"
      />
    </div>
  </div>
</template>
<script>
import { useStore } from "effector-vue/composition";
import store, { EventBus } from "../../../../store";
import { waitingInfoStore } from "../../../../effector/users/waitingInfo";
import { setSendBugModalEvent } from "../../../../effector/modals";
import {
  oneCallAndWaitingUser,
  hasPrivilege,
  basicView,
} from "../../../../utils/privileges";
import { canIShowOrganigram } from "../../../../utils/basicFunctions";
import CallLateralPanel from "./callLateralPanel.vue";
import ClickOutside from "vue-click-outside";
import { getDirectCallInviteLink } from "../../../../lib/wsMsg";
import { unseenMessagesToMe } from "../../../../effector/message";
import SettingsCall from "./settingsCall.vue";
import AddParticipantsCall from "./addParticipantsCall.vue";
import { isConferenceHallCall } from "../../../../utils/calls";
const hasOwn = Object.prototype.hasOwnProperty;
export default {
  directives: {
    ClickOutside,
  },
  components: { CallLateralPanel, SettingsCall, AddParticipantsCall },
  props: ["maximizeScreen", "isConferenceCall", "getAmIModerator", "showAskAiUser", "removeParticipantPerson", "isMobile", "showTimeLine"],
  data() {
    const effector = {
      waitingUsesEffect: waitingInfoStore,
      unseenMessagesToMe: unseenMessagesToMe,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      showLateralPanel: false,
      dataType: null,
      waitingRoomFor: false,
      showModalSettings: false,
      instaLink: "",
      // Effector
      ...effector,
    };
  },
  mounted() {
    EventBus.$on("openModalSettingsCall", this.openModalSettings);
    EventBus.$on("openAiPanel", this.openAiPanel);
    EventBus.$on("closeAiPanel", this.onClose);
  },
  unmounted() {
    EventBus.$off("openModalSettingsCall", this.openModalSettings);
    EventBus.$off("openAiPanel", this.openAiPanel);
    EventBus.$off("closeAiPanel", this.onClose);
  },
  methods: {
    openAiPanel(){
      setTimeout(() => {
        this.waitingRoomFor = null;
        this.showLateralPanel = true;
        this.dataType = 'aiText';
      }, 300);
    },
    openGpt4bot() {
      EventBus.$emit("openCloseGpt4bot");
    },
    async openModalSettings() {
      this.showModalSettings = true;
      this.instaLink = await getDirectCallInviteLink();
    },
    openBugModal() {
      setSendBugModalEvent(true)
    },
    closeModalSettings() {
      this.showModalSettings = false;
    },
    handlerOpenVisitor() {
      EventBus.$emit("handlerOpenVisitor");
    },
    async copyLink(evt) {
      let instalink = this.instaLink; //await getDirectCallInviteLink();
      const selBox = document.createElement("textarea");
      selBox.style.position = "fixed";
      selBox.style.left = "0";
      selBox.style.top = "0";
      selBox.style.opacity = "0";
      selBox.value = instalink;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand("copy");
      document.body.removeChild(selBox);
      evt.target.classList.add("greenClass");
      setTimeout(() => {
        evt.target.classList.remove("greenClass");
      }, 1000);
    },
    openUsers() {
      this.showModalSettings = false;
      setTimeout(() => {
        this.handlerShowLateralPanel(true, "users");
      }, 250);
    },
    onClose(event) {
      if (event?.target?.className === 'v-list-item__title') return;
      this.handlerShowLateralPanel(false, null);
    },
    handlerShowLateralPanel(show, dataType, uuid = false) {
      this.waitingRoomFor = uuid;
      this.showLateralPanel = show;
      this.dataType = dataType;
    },
    gotoPage(route) {
      return this.setCurrentContentVisile(route, true, this.$router);
    },
    async goToDefaultPage() {
      await this.gotoPage(store.state.user.userSettings.startView);
      await this.$nextTick(); // Wait for render after navigation
      setTimeout(() => {
        this.setAsExternalIframeCall();
        EventBus.$emit("openCloseSidebar");
      }, 50);
    },
    setAsExternalIframeCall() {
      const remoteStreamsKey = Object.keys(store.state.remoteBridgeStreams)[0];
      if (remoteStreamsKey) {
        Object.values(store.state.remoteBridgeStreams)[0].externalCall = true;
        window.top.document.store.state.remoteBridgeStreams = JSON.parse(JSON.stringify(store.state.remoteBridgeStreams));
        window.top.document.store.state.user.inBridgeCall = store.state.user.inBridgeCall;
        window.top.document.store.state.user.bridgeCallInfo = Object.values(window.top.document.store.state.remoteBridgeStreams)[0];
        store.removeRemoteBridgeStreams(remoteStreamsKey);
      }
    },
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    oneCallAndWaitingUser(uuid) {
      return oneCallAndWaitingUser(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
  },
  computed: {
    getConnectedCoffeeBreak() {
      let tempCoffeeBreak = 0;
      for (let uuid in store.state.group) {
        const person = store.state.group[uuid];
        if (person.connected && person.user && person.user.presentFrom) {
          if (
            person.user.activity === "Coffee break"
          ) {
            tempCoffeeBreak++;
          }
        }
      }
      return tempCoffeeBreak;
    },
    getIsConferenceHall() {
      return isConferenceHallCall(this.getCallUUID);
    },
    getCallUUID() {
      const callUUID = Object.keys(this.state.remoteBridgeStreams)[0];
      return callUUID;
    },
    totalMessagesNotification() {
      return (
        this.unseenMessagesToMe + this.state.persisted.totalNotificationFile
      );
    },
    getWaitingUsesEffect() {
      return this.waitingUsesEffect;
    },
    getconnectedWaitingrooms() {
      const calledVisitors = Object.entries(this.getWaitingUsesEffect.called)
        .filter(([uuid, value]) => !!value)
        .map(([uuid, value]) => uuid);
      const total = store.getWaitingRoomList().length - calledVisitors.length;
      return total;
    },
    showOrganigram() {
      return canIShowOrganigram();
    },
    isBasicView() {
      return basicView();
    },
    featureOrganizationOption() {
      return store.state.namespaceSettings.featureOrganization;
    },
    amIAdmin() {
      return store.getUserIsAdmin(this.ownUUID);
    },
    isDark() {
      return store.state.persisted.isDark;
    },
    assistantsUser() {
      let finalResult = [];
      if (this.state.user.assists && this.state.user.assists.length > 0) {
        finalResult = JSON.parse(JSON.stringify(this.state.user.assists));
      }
      if (
        this.state.user.waitingRoomAssists &&
        this.state.user.waitingRoomAssists.length > 0
      ) {
        this.state.user.waitingRoomAssists.forEach((uuid) => {
          if (finalResult.indexOf(uuid) == -1) {
            finalResult.push(uuid);
          }
        });
      }
      return finalResult.filter(uuid => hasOwn.call(this.state.group, uuid) && !!this.state.group[uuid]);
    },
    showWaitingRoom() {
      return this.state.user.userSettings.showWaitingRoom;
    },
  },
};
</script>
<style lang="scss">
.waitingRoomBadge{
  .v-badge__wrapper{
    // position: fixed !important;
    .v-badge__badge{
      padding-left: 2px;
      padding-right: 0;
      position: absolute;
      // width: 100%;
    }
  }
}
</style>
<style scoped lang="scss">
.w100{
  width: 100%!important;
}
.askmeContainerCall{
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.darkGreyIcon {
  background-color: #464b4e !important;
  border-color: #464b4e !important;
  height: 36px;
  width: 36px !important;
  min-width: 100%;
  text-align: center;
  border-radius: 0px;
}
.color-white {
  color: white;
}
.sidebarCall {
  background-color: #202124;
  height: 100%;
}
.w55 {
  width: 55px;
}
.waitingRoomBadge {
  font-size: 10px !important;
  height: 16px !important;
  min-width: 16px !important;
  // padding: 2px 6px !important;
  padding: 0;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  // align-items: center;
  // justify-content: center;
  text-indent: 0;
  top: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  white-space: nowrap;
  border-radius: 50% !important;
  font-style: normal;
}
</style>