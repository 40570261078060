import { webLicensedBaseFeatures } from "../../sharedsrc/licensedFeatures";
import store from '../store';
import { makeid } from '../lib/wsConnect';
import { getStatusColorByUser } from './status';
import { personIsInBridgeCall, userInStaticRoom } from "./calls";
import { isVisitor, hasPrivilege, isAdmin } from './privileges';
import Vue from '@vue/compat';
import moment from "../../sharedsrc/moment";
import { bridgeInfoStore } from "../effector/users/bridgeInfo";

const hasOwn = Object.prototype.hasOwnProperty;

/**
 * Retrieves the user's current timezone based on their browser settings.
 * Refer to: {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/resolvedOptions}
 * @returns {string} The IANA timezone string representing the user's local timezone.
 */
export function getBrowserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Returns TZ offset in RFC 3339 and ISO 8601 compatible mode (Ex: +01:00)
 * @param {Date|string=} date Date in a reasonable format
 * @param {string=} time Time in a reasonable format
 * @returns {string} The guessed timezone string for current environment
 */
export function getTimezone(date = '', time = '') {
  let d = new Date();
  if (date && typeof date === 'object' && date instanceof Date && !isNaN(date)) {
    d = date; // Input is native date object
  }
  if (date && date !== d) {
    const dateTime = ('' + (date || '').trim() + ' ' + (time || '').trim() + '').trim();
    if (dateTime) {
      const dateSplit = dateTime.split(/[ .:T-]/).map(v => parseInt(v, 10));
      const [year, month, day, hour, minute, second] = dateSplit;
      const dateObject = new Date(year, month - 1, day, (hour || 0), (minute || 0), (second || 0));
      if (dateObject.getTime()) {
        d = dateObject;
      } else {
        console.warn('getTimezone: guessed invalid date object', date, time);
      }
    } else {
      console.warn('getTimezone: got emptyish parameters', date, time);
    }
  } else if (time) {
    console.warn('getTimezone: got time without date', date, time);
  }
  // Extract timezone from date string
  const tzMatch = d.toString().match(/([+-][\d]{2})([\d]{2})\s/);
  if (tzMatch && tzMatch.length) return tzMatch[1] + ':' + tzMatch[2];
  // Extract timezone from offset
  const tzOffset = d.getTimezoneOffset() * -1;
  const hours = Math.floor(tzOffset / 60).toString()
    .replace(/^\d/, '+$&')
    .replace(/^([+-])(\d)$/, '$1\x30$2') // "0".charCodeAt(0) === 0x30
  const minutes = Math.abs(tzOffset % 60).toString().padStart(2, '0');
  return hours + ":" + minutes;
}

export function sortByName(array) {
  if (array.length > 0) {
    return array.sort((a, b) => {
      return ((a.user || {}).name || a.uuid || '').toString().localeCompare(
        (b.user || {}).name || b.uuid || ''
      );
    });
  } else {
    return [];
  }
}

export function sortByLastName(array) {
  if (array.length > 0) {
    return array.sort((a, b) => {
      const au = a.user || {};
      const bu = b.user || {};
      const ak = `${(au.lastName || '') + ' ' + (au.firstName || '') + ' ' + (au.name || '') + ' ' + (au.uuid || '')}`.replace(/\s{2,}/, ' ').trim();
      const bk = `${(bu.lastName || '') + ' ' + (bu.firstName || '') + ' ' + (bu.name || '') + ' ' + (bu.uuid || '')}`.replace(/\s{2,}/, ' ').trim();
      return ak.toString().localeCompare(bk);
    });
  } else {
    return [];
  }
}

function getColorPref(color){
  if (color === '#008000') {
    // green status (Connected)
    return 0;
  } else if (color === '#795548') {
    // brown status (Coffee)
    return 1;
  } else if (color === '#ffa500') {
    // yellow status (Busy)
    return 2;
  } else if (color === '#0abce6') {
    // blue status
    return 3;
  } else if (color === '#ff0000') {
    // red status
    return 4;
  } else if (color === '#7208ff') {
    // purple status (Holidays)
    return 5;
  } else if (color === '#fd939e') {
    // pink status (Ill)
    return 6;
  } else if (color === '#000000') {
    // black status (NoStatus)
    return 7;
  } else if (color === '#808080') {
    // grey status (Disconnected)
    return 8;
  }
}

export function getPersonsSortByColor(personA, personB) {
  const personA_colorPref = getColorPref(setBorderByStatus(personA));
  const personB_colorPref = getColorPref(setBorderByStatus(personB));
  // (-1 A comes first, 1 B comes first, 0 equal) as function in:
  //  ../components/content/organigram/newOrganisationGrid.vue:228 (safeLocaleCompare(a, b))
  return personA_colorPref > personB_colorPref ? 1 : personB_colorPref > personA_colorPref ? -1 : 0;
}

export function getFinalPersonsSortedByColor(persons) {
  const connectedArray = [];
  const coffeeBreakArray = [];
  const disconnectedArray = [];
  const busyArray = [];
  const redArray = [];
  const holidaysArray = [];
  const illArray = [];
  const noStatusArray = [];
  for (const i in persons) {
    if (hasOwn.call(persons, i)) {
      const person = persons[i];
      const result = setBorderByStatus(person);
      if (result === '#008000') {
        // green status
        connectedArray.push(person);
      } else if (result === '#795548') {
        // brown status
        coffeeBreakArray.push(person);
      } else if (result === '#0abce6') {
        // blue status
        busyArray.push(person);
      } else if (result === '#808080') {
        // grey status
        disconnectedArray.push(person);
      } else if (result === '#ffa500') {
        // yellow status
        busyArray.push(person);
      } else if (result === '#ff0000') {
        // red status
        redArray.push(person);
      } else if (result === '#7208ff') {
        // purple status
        holidaysArray.push(person);
      } else if (result === '#fd939e') {
        // pink status
        illArray.push(person);
      }
      else if (result === '#000000') {
        // black status
        noStatusArray.push(person);
      }
    }
  }
  let finalResult = [];
  finalResult = finalResult.concat(
    sortByLastName(connectedArray),
    sortByLastName(coffeeBreakArray),
    sortByLastName(busyArray),
    sortByLastName(redArray),
    sortByLastName(holidaysArray),
    sortByLastName(illArray),
    sortByLastName(noStatusArray),
    sortByLastName(disconnectedArray),
  );
  return finalResult;
}

export function checkListSort(persons, apart) {
  const setting = apart;
  if (setting) {
    switch (setting) {
      case 'alphabetical':
        return sortByName(persons);
      case 'statusColor':
        return getFinalPersonsSortedByColor(persons);
      case 'lastName':
        return sortByLastName(persons);
      default:
        return getFinalPersonsSortedByColor(persons);
    }
  } else {
    return getFinalPersonsSortedByColor(persons);
  }
}
export function setBorderByStatus(person) {
  if (!person || !person.user) return;
  return getStatusColorByUser(person.user, person.connected);
}

export function getBorderByStatusFull(person, customPx) {
  if (!person || !person.user) return;
  const userInBridgeCall = personIsInBridgeCall(person.uuid || person.user.uuid);
  if (userInBridgeCall && person.user.activity !== 'No status') {
    if (customPx) {
      if (userInStaticRoom(person.uuid || person.user.uuid)) {
        return `border: ${customPx} solid ${setBorderByStatus(person)}`;
      }
      return `border: ${customPx} dashed ${setBorderByStatus(person)}`;
    }
    if (userInStaticRoom(person.uuid || person.user.uuid)) {
      return `border: 4px solid ${setBorderByStatus(person)}`;
    }
    return `border: 3px dashed ${setBorderByStatus(person)}`; // Intended for user grid.
  } else {
    const isNoStatus = person.user.activity === 'No status' ? `outline: 1px solid white` : '';
    if (customPx) {
      return `border:${customPx} solid ${setBorderByStatus(person)}; ${isNoStatus}`;
    }
    return `border:4px solid ${setBorderByStatus(person)}; ${isNoStatus}`;
  }
}

export function getCustomBorderByStatusFull(person, position, removeRadius = false) {
  if (!person || !person.user) return;
  const userInBridgeCall = personIsInBridgeCall(person.uuid || person.user.uuid);
  let defaultCss;
  if (userInBridgeCall && person.user.activity !== 'No status') {
    defaultCss = `border: 4px dashed ${setBorderByStatus(person)};`; // https://gitlab.ra-micro.de/devcups/voffice/-/issues/490#note_28807
  } else {
    const isNoStatus = person.user.activity === 'No status' ? `outline: 1px solid white;` : '';
    defaultCss = `border: 4px solid ${setBorderByStatus(person)}; ${isNoStatus}`;
  }
  if (position) {
    let customCss;
    // const boxShadowCss = `box-shadow: 0 3px 1px 0 rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);`
    if (position == 'right') {
      if (!removeRadius){
        customCss = `${defaultCss} border-right: 0; border-radius: 5px 0px 0px 0px!important;`;
      } else {
        customCss = `${defaultCss} border-right: 0; border-radius: 0px!important;`;
      }
    } else if (position == 'left') {
      if (!removeRadius){
        customCss = `${defaultCss} border-left: 0; border-radius: 0px 5px 0px 0px!important;`;
      } else {
        customCss = `${defaultCss} border-left: 0; border-radius: 0px!important;`;
      }
    } else if (position == 'third') {
      customCss = `${defaultCss} border-radius: 0px!important;`;
    }
    return customCss;
  }
  return defaultCss;
}

/* eslint-disable array-callback-return */
export function getUserOrgaData(userUUID) {
 const organisation = store.state.namespaceSettings.processedOrganisation;
 const teams = organisation.teams;
 const userTeams = Object.entries(teams).filter(([teamName, value]) => {
  if (value.members.indexOf(userUUID) !== -1) return true;
 }).map(([teamName, value]) => teamName)
 const sectionTeams = Object.entries(teams).filter(([teamName, value]) => {
  if (value.members.indexOf(userUUID) !== -1) return true;
 }).map(([teamName, value]) => value.section)

 return { 'teams':userTeams, 'sections':sectionTeams}
}

export function showDailyReportList() {
  const organisation = store.state.namespaceSettings.organisation;
  if (store.state.namespaceSettings.showDailyReport && organisation) {
    const showDailyReportValue = store.state.namespaceSettings.dailyReportValue;
    if (showDailyReportValue == 'team') {
      const teams = organisation.filter((e) => e.team);
      let teamUsers = [];
      for (const key in teams) {
        if (Object.hasOwnProperty.call(teams, key)) {
          const team = teams[key];
          if (team.teamMembers.indexOf(store.state.ownUUID) !== -1 || team.supervisor.indexOf(store.state.ownUUID) !== -1) {
            const temporalArray = teamUsers;
            teamUsers = temporalArray.concat(team.teamMembers);
          }
        }
      }
      // return all the user uuids list
      return teamUsers;
    } else if (showDailyReportValue == 'department') {
      let imDeptSupervisor = [];
      let teamUsers = [];
      // step 1 check if I am a department supervisor,
      // if true, get all the team supervisor uuids and team member uuids of the department
      organisation.filter((e) => e.departmentRights).map((e) => {
        if (e.supervisor.indexOf(store.state.ownUUID) !== -1) {
          const temporalArray = imDeptSupervisor;
          imDeptSupervisor = temporalArray.concat(e.department)
        }
      });
      const teams = organisation.filter((e) => e.team);
      teams.filter((e) => {
        if (imDeptSupervisor.indexOf(e.department) !== -1) {
          const temporalArray = teamUsers;
          teamUsers = temporalArray.concat(e.teamMembers).concat(e.supervisor);
        }
      });
      // step 2
      // if I part of a team, get all the team member uuids
      let imPartOfDepts = [];
      for (const key in teams) {
        if (Object.hasOwnProperty.call(teams, key)) {
          const team = teams[key];
          if (team.teamMembers.indexOf(store.state.ownUUID) !== -1 || team.supervisor.indexOf(store.state.ownUUID) !== -1) {
            if (imPartOfDepts.indexOf(team.department) == -1) imPartOfDepts.push(team.department);
          }
        }
      }
      imPartOfDepts.forEach(dept => {
        teams.filter((e) => e.department === dept).map((e) => {
          const temporalArray = teamUsers;
          teamUsers = temporalArray.concat(e.teamMembers);
        });
      });
      // return all the user uuids list
      return teamUsers;
    } else if (showDailyReportValue == 'section') {
      const sections = organisation.filter((e) => e.sectionRights);
      const mySections = sections.filter((e) => e.supervisor.indexOf(store.state.ownUUID) !== -1).map((e) => {
        return e.section;
      });
      const departments = organisation.filter((e) => e.departmentRights);
      const teams = organisation.filter((e) => e.team);
      let departmentSupervisorsOfMySections = []
      departments.filter((e) => mySections.indexOf(e.section) !== -1).map((e) => {
        let temporalArray = departmentSupervisorsOfMySections;
        departmentSupervisorsOfMySections = temporalArray.concat(e.supervisor);
      });
      let teamSupervisorsAndMembersOfMySections = []
      teams.filter((e) => mySections.indexOf(e.section) !== -1).map((e) => {
        let temporalArray = teamSupervisorsAndMembersOfMySections;
        teamSupervisorsAndMembersOfMySections = temporalArray.concat(e.supervisor).concat(e.teamMembers);
      });
      return teamSupervisorsAndMembersOfMySections;
    }
  }
}
/* eslint-enable array-callback-return */

export function isDailyReportEnabled() {
  return store.state.namespaceSettings.showDailyReport
}

export function getFillColorByStatus(person) {
  return setBorderByStatus(person);
}

export function getSubjectVisitor(uuid) {
  if (isVisitor(uuid)) {
    return store.state.group[uuid].user.visitorData.subjectDisplay;
  }
}

export function canIseeSpeechliveIcon() {
  return ((store.state.user || {}).speechlive || {}).activated && canShowSpeechliveByDomain();
}
export function userHasSpeechLiveActivated(person) {
  return ((person.user || {}).speechlive || {}).activated && canShowSpeechliveByDomain();
}
export function canShowSpeechliveByDomain() {
  return webLicensedBaseFeatures.isSpeechliveAvailable;
}

export function locationTranslated(person) {
  if (!person || !person.userLocation) return;
  const key = `components.locations.${person.userLocation}`;
  const translation = Vue.prototype.$t(key) || key;
  return key === translation ? `${person.userLocation}` : translation;
}

export function getPictureSize() {
  const size = store.state.namespaceSettings.pictureSize || 'square';
  switch (size) {
    case 'square':
      return true;
    case 'rectangle':
      return false;
    default:
      return true;
  }
}

export function getAvatarByType(uuid) {
  const type = store.state.namespaceSettings.pictureSize || 'square';
  if (type === 'square') {
    return store.getAvatarForUuid(uuid);
  } else if (type === 'rectangle') {
    return store.getAvatarRectangleForUuid(uuid);
  }
}

export function hasUserImage(uuid) {
  const image = getAvatarByType(uuid);
  return image === 'img/default_profile_picture.png' ? false : true;
}

export function canIShowOrganigram(uuid) {
  if (store.state.namespaceSettings.featureOrganization && !store.state.namespaceSettings.basicView) { //&& Object.keys(store.state.namespaceSettings.organisation).length > 0
    const userUUID = uuid || store.state.ownUUID
    const data = store.state.namespaceSettings.showOrganigrammFor
    let canIShow = false
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      switch (element) {
        case 'all':
          if (hasPrivilege(userUUID)) canIShow = true
          break;
        case 'admin':
          if (isAdmin(userUUID)) canIShow = true
          break;
        case 'manager':
            if (isOrgaSupervisor(userUUID)) canIShow = true
            break;
        // case 'sectionManager':
        //   if (userSupervisorSection(userUUID)) canIShow = true;
        //   break;
        // case 'departmentManager':
        //   if (userSupervisorDepartment(userUUID)) canIShow = true;
        //   break;
        // case 'teamManager':
        //   if (userSupervisorTeam(userUUID)) canIShow = true;
        //   break;
        default:
          break;
      }
    }
    return canIShow
  } else {
    return false
  }
}

function getDepartmentSupervisorUUID(departmentName) {
  const departmentsList = store.state.namespaceSettings.processedOrganisation.departments;
  if (departmentsList[departmentName] && departmentsList[departmentName].supervisors[0]) {
    return departmentsList[departmentName].supervisors[0].uuid;
  }
  return undefined;
}

export function getAllUsersWithPrivileges() {
  let userList = [];
  const allUsers = Object.keys(store.state.group);
  allUsers.forEach(userUUID => {
    if (hasPrivilege(userUUID) && userList.indexOf(userUUID) == -1) {
      userList.push(userUUID);
    }
  });
  return userList;
}


export function getUsersFromOrganigramByName(type, typeName) {
  const sectionsList = store.state.namespaceSettings.processedOrganisation.sections;
  const departmentsList = store.state.namespaceSettings.processedOrganisation.departments;
  const teamsList = store.state.namespaceSettings.processedOrganisation.teams;
  let usersList = [];
  let sectionSupervisor = ''
  let departmentSupervisor = ''
  switch (type) {
    case 'section':
      for (const teamName in teamsList) {
        if (Object.hasOwnProperty.call(teamsList, teamName)) {
          const team = teamsList[teamName];
          if (team.section === typeName) {
            if (team.supervisors[0] && usersList.indexOf(team.supervisors[0].uuid) == -1) usersList.push(team.supervisors[0].uuid);
            team.members.forEach(userUUID => {
              if (usersList.indexOf(userUUID) == -1) usersList.push(userUUID);
            });
            if (sectionsList[team.section].supervisors[0]) {
              sectionSupervisor = sectionsList[team.section].supervisors[0].uuid;
              if (usersList.indexOf(sectionSupervisor) == -1) usersList.push(sectionSupervisor);
            }
            for (let i = 0; i < sectionsList[team.section].departments.length; i++) {
              const department = sectionsList[team.section].departments[i];
              const getDepartmentSupervisor = getDepartmentSupervisorUUID(department);
              if (usersList.indexOf(getDepartmentSupervisor) == -1) usersList.push(getDepartmentSupervisor);
            }
          }
        }
      }
      break;
    case 'department':
      for (const teamName in teamsList) {
        if (Object.hasOwnProperty.call(teamsList, teamName)) {
          const team = teamsList[teamName];
          if (team.department === typeName) {
            if (team.supervisors[0] && usersList.indexOf(team.supervisors[0].uuid) == -1) usersList.push(team.supervisors[0].uuid);
            team.members.forEach(userUUID => {
              if (usersList.indexOf(userUUID) == -1) usersList.push(userUUID);
            });
            if (departmentsList[team.department].supervisors[0]) {
              departmentSupervisor = departmentsList[team.department].supervisors[0].uuid;
              if (usersList.indexOf(departmentSupervisor) == -1) usersList.push(departmentSupervisor);
            }
          }
        }
      }
      break;
    case 'team':
      if (teamsList[typeName]) {
        usersList = teamsList[typeName].members;
        if (teamsList[typeName].supervisors[0] && usersList.indexOf(teamsList[typeName].supervisors[0].uuid) == -1) usersList.push(teamsList[typeName].supervisors[0].uuid)
      }
      break;
    default:
      break;
  }
  return usersList
}

export function hasAdditionalStatus(person) {
  if (
    person?.user?.noCallsAdditionalInfo?.length > 0 ||
    person?.user?.additionalStatus?.length > 0
  ) {
    return true;
  } else {
    return false;
  }
}

export function checkIsDateInRange(startDate, endDate, date) {
  // Create moment objects for the start and end dates at the start of the day
  const start = moment(startDate).startOf('day');
  const end = moment(endDate).endOf('day');

  // Create moment object for the date to check, for consistency also at the start of the day
  const checkDate = moment(date).startOf('day');

  // Create a range from start to end date
  const range = moment.range(start, end);

  // Check if checkDate is within the range or falls on the same day as start or end
  return range.contains(checkDate) || checkDate.isSame(start, 'day') || checkDate.isSame(end, 'day');
}

export function checkIsDateTimeInRange(startDate, endDate, dateToCheck) {
  // Create consts with the moment objects to check dates
  const start = moment(startDate);
  const end = moment(endDate);
  const checkDate = moment(dateToCheck);
  // Create a range from start to end date
  const range = moment.range(start, end);
  // Check if checkDate is within the range
  return range.contains(checkDate);
}

export function userHasHolidaysSetted(uuid) {
  if (!uuid) return false
  const person = (store.state.group[uuid] || {}).user
  if (
    person &&
    person.startHolidays &&
    person.startHolidays.length > 0
  ) {
    let startDate = new Date(person.startHolidays);
    let endDate = new Date(person.endHolidays);
    let nowDate = new Date(
      Date.UTC(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    );
    if (checkIsDateInRange(startDate, endDate, nowDate)) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}
export function userHasIllnessSetted(uuid) {
  if (!uuid) return false
  const person = (store.state.group[uuid] || {}).user
  if (
    person &&
    person.startIllness &&
    person.startIllness.length > 0
  ) {
    const now = new Date();
    let startDate = new Date(person.startIllness);
    let endDate = new Date(person.endIllness);
    let nowDate = new Date(
      Date.UTC(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      )
    );
    // Partial-day absence tracking: #512
    if (/T/.test(person.startIllness)) {
      const nowISO = new Date().toISOString();
      return checkIsDateTimeInRange(person.startIllness, person.endIllness, nowISO);
    }
    if (checkIsDateInRange(startDate, endDate, nowDate)) {
      return true;
    } else {
      return false;
    }
  }
  return false;
}

export function checkForUrls(message, newline = true) {
  if (!message) return "";
  const linkExpBlacklist = /["'>](((https?|ftp|file):\/\/)?[-A-Z0-9+&@'#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])(["']|<\/a>)/gmi,
    linkExp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@'#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig,
    wwwExp = /(^|[^\/]\b)(www\.[^\s<]+(\b|$))/gim,
    hyperlinkBlacklist = [];
  // Search hyperlinks with <a> tag
  message.replace(linkExpBlacklist, (...args) => { if (hyperlinkBlacklist.indexOf(args[1]) === -1) hyperlinkBlacklist.push(args[1]); });
  // Replace hyperlinks ignoring hyperlink blacklist
  return message
    .replace(linkExp, (...args) => {
      if (hyperlinkBlacklist.indexOf(args[1]) === -1) {
        return `${newline ? '<br>' : ''}<a style="text-decoration: none;" rel="nofollow noopener noreferrer" target="_blank" href="${args[1]}">${args[1]}</a>${newline ? '<br>' : ''}`;
      }
      return args[0];
    })
    .replace(wwwExp, (...args) => {
      if (hyperlinkBlacklist.indexOf(args[2]) === -1) {
        return `${args[1]}${newline ? '<br>' : ''}<a style="text-decoration: none;" rel="nofollow noopener noreferrer" target="_blank" href="http://${args[2]}">${args[2]}</a>${newline ? '<br>' : ''}`;
      }
      return args[0];
    });
}
export function checkForEmojis(message) {
  if (!message) return "";
  const regexExp = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;
  let outPutMessage = message
  if (outPutMessage.match(regexExp) && (outPutMessage.match(regexExp).length > 1 || (outPutMessage.match(regexExp).length == 1 && outPutMessage.trim().length > 2))) {
    outPutMessage = outPutMessage.replace(regexExp, "<span class='emojisInText'>$1</span>");
  }
  if (outPutMessage.match(regexExp) && outPutMessage.match(regexExp).length == 1 && outPutMessage.trim().length == 2) {
    outPutMessage = outPutMessage.replace(regexExp, "<span class='singleEmojiInText'>$1</span>");
  }
  return outPutMessage;
}

export function userInOrganigram(uuid) {
  if (uuid && store.getProcessedUserList()[uuid] !== undefined) {
    return true;
  }
  return false;
}

export function getUserActivityFunction(person) {
  const redStatus = (
    person.user.activity == "Out of Office" ||
    person.user.activity == "No Calls" ||
    person.user.activity == "Break" ||
    person.user.activity == "No Calls" ||
    person.user.activity == "Only phone") ? true : false;
  if (
    (!person.connected && person.user.activity !== "Holidays" && !redStatus && person.user.activity !== "No status")
  ) {
    return Vue.prototype.$t("status.Offline");
  } else {
    const key = `status.${person.user.activity}`;
    const translation = Vue.prototype.$t(key) || key;
    return key === translation ? `${person.user.activity}` : translation;
  }
}

export function validURL(message) {
  if (!message) return;
  const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(message);
}

// new organigram functions

export function getOrganigramTeams(array) {
  let result = [];
  for (let i = 0; i < array.length; i++) {
    const element = array[i];

    if (element.children && element.children.length > 0) {
      const filteredChildren = getOrganigramTeams(element.children);

      if (filteredChildren.length > 0) {
        result.push(...filteredChildren);
      }

      if (element.children[0] && element.children[0].isUser) {
        result.push(element);
      }
    }
  }
  return result;
}

export function getOrganigramTeamsByUser(array, userUUID) {
  let result = [];
  // Loop through each element in the array
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    // Check if element has children and if there are any children to process
    if (element.children && element.children.length > 0) {
      // Pass userUUID to the recursive function call
      const filteredChildren = getOrganigramTeamsByUser(element.children, userUUID);
      // If filtered children are returned, add them to the result
      if (filteredChildren.length > 0) {
        result.push(...filteredChildren);
      }
      // Check if any child's uuid matches the userUUID
      for (const child of element.children) {
        if (child.uuid === userUUID) {
          // If a match is found, push the current element to the result
          result.push(element);
          break; // No need to search further after finding a match
        }
      }
    }
  }
  return result;
}

export function getOrganigramTeamsByUserLevel3(array, currentLevel = 0, userUUID) {
  // Initialize an array to hold the results at level 3
  let levelThreeElements = [];

  // Check if the current level is 3 (base 0)
  // If yes, push all items into the result array and return it
  if (currentLevel === 1) {
      return array;
  }

  // If not at level 2, recursively check each item's children
  for (let item of array) {
    // console.log(item, currentLevel)
      if (item.children && item.children.length > 0) {
          // Recurse into the children array, incrementing the level
          const childrenResult = getOrganigramTeamsByUserLevel3(item.children, currentLevel + 1, userUUID);
          // Concatenate results from children
          levelThreeElements = levelThreeElements.concat(childrenResult);
      }
  }

  const finalResult = levelThreeElements.filter(element => {
    return element.children && element.children.some(child => {
      return child.supervisors && child.supervisors.includes(userUUID);
    });
  });
  return finalResult;
}

export function getOrganigramTeamsByUserLevel4(array, currentLevel = 0, userUUID) {
    // Initialize an array to hold the results at level 3
    let levelThreeElements = [];

    // Check if the current level is 3 (base 0)
    // If yes, push all items into the result array and return it
    if (currentLevel === 2) {
        return array;
    }

    // If not at level 3, recursively check each item's children
    for (let item of array) {
      // console.log(item, currentLevel)
        if (item.children && item.children.length > 0) {
            // Recurse into the children array, incrementing the level
            const childrenResult = getOrganigramTeamsByUserLevel4(item.children, currentLevel + 1, userUUID);
            // Concatenate results from children
            levelThreeElements = levelThreeElements.concat(childrenResult);
        }
    }

    const finalResult = levelThreeElements.filter(element => {
      return element.children && element.children.some(child => {
        return child.supervisors && child.supervisors.includes(userUUID);
      });
    });
    return finalResult;
}

export function getOrganigramSupervisors(array) {
  const supervisorsSet = new Set();

  function traverse(array) {
    // Add each supervisor to the set (duplicates will be automatically ignored)
    if (array.supervisors) {
      array.supervisors.forEach((supervisor) => {
        supervisorsSet.add(supervisor);
      });
    }

    // Recurse into child departments if they exist
    if (array.children && array.children.length > 0) {
      array.children.forEach(traverse);
    }
  }

  // Start recursion for each top-level department
  array.forEach(traverse);

  // Convert the set of supervisors back to an array
  return Array.from(supervisorsSet);
}

export function isOrgaSupervisor(uuid) {
  const supervisors = getOrganigramSupervisors(store.state.namespaceSettings.newOrganigram || [])
  return supervisors.find(userUUID => userUUID === uuid)
}

export function checkUuidPresenceInOrganigram(uuid) {
  function traverseArray(arr) {
    return arr.reduce((result, obj) => {
      if (obj.children && obj.children.length > 0) {
        const filteredChildren = traverseArray(obj.children || []);
        if (filteredChildren.length > 0) {
          result.push({ ...obj, children: filteredChildren });
        }
      }
      if (
        (obj.uuid && obj.uuid === uuid) ||
        (obj.supervisors && obj.supervisors.includes(uuid))
      ) {
        const found = result.some((e) => e.id === obj.id);
        if (!found) {
          result.push(obj);
        }
      }
      return result;
    }, []);
  }

  const dataResult = traverseArray(store.state.namespaceSettings.newOrganigram || []);
  return dataResult;
}
// END new organigram functions

export function now() {
  const time = Date.now();
  const last = now.last || time - 1;
  return now.last = time > last ? time : last + 1; // eslint-disable-line no-return-assign
}

/**
 * @param {number} length
 */
export function rnd(length = 32) {
  try {
    const randomBytes = new Uint8Array(length);
    crypto.getRandomValues(randomBytes);
    const thing = Array.from(randomBytes, byte => { const s = byte.toString(36); return (s.slice(1) || s); }).join('');
    return thing.slice(-length);
  } catch (err) {
    return makeid(length).toLowerCase();
  }
}

/**
 * @param {number} length
 */
export function uid(length = 32) {
  const ts = now().toString(36).split('');
  const id = rnd(ts.length + 1).split('');
  const mix = ts.reduce((p, c, i) => (p + id[ts.length - i] + c), '') + id[0];
  return (((length > mix.length) ? rnd(length - mix.length) : '') + mix).slice(-length);
}

/**
 * @param {string} str
 */
export function isJSON(str) {
  if (!str || typeof str !== 'string') return false;
  // https://github.com/prototypejs/prototype/blob/9e78eff0245c23b3104a814db0920f9b9740f938/src/prototype/lang/string.js#L715
  str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@');
  str = str.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']');
  str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
  return (/^[\],:{}\s]*$/).test(str);
}

/**
 * @param {string} str
 */
export function parseJSON(str) {
  let o = isJSON(str) || undefined;
  try {
    o = o && JSON.parse(str);
  } catch (e) {
    o = undefined;
  }
  return o;
}

export function isAiUser (uuid) {
  if (!uuid) return false;
  const person = (store.state.group[uuid] || {}).user;
  return person?.aiUser;
}

export function getUsersInCall(callUUID) {
  const bridgeInfo = bridgeInfoStore.getState();
  if (
    !callUUID ||
    !bridgeInfo ||
    !bridgeInfo.calls[callUUID]
  ) {
    return [];
  }
  const callInfo = bridgeInfo.calls[callUUID];
  return callInfo.users.filter((user) => user !== store.state.ownUUID);
}