<template>
  <v-dialog :model-value="showSendMessageBeforeConferenceModal.show" @update:model-value="showSendMessageBeforeConferenceModal.show = $event" persistent max-width="580" @keydown.esc="closeModal">
    <template>
      <v-card class="mx-auto">
        <HeaderModal
          :titleModal="$t('components.groups.sendMessageToAllParticipants')"
          :closeModalFunction="closeModal"
        >
          <v-spacer></v-spacer>
        </HeaderModal>
        <v-card-text class="py-0 pt-4">
         <v-textarea
            counter
            contenteditable="true"
            :label="$t('components.sendMessageModal.message')"
            :rules="rules"
            :model-value="messageText" @update:model-value="messageText = $event"
            :no-resize="true"
            maxlength="250"
            autofocus
            id="messageTextarea"
          ></v-textarea>
        </v-card-text>

        <FooterModal :closeModalFunction="closeModal" :showCancel="true">
           <v-btn
            color="primary"
            @click="
              sendMessage();
            "
            :disabled="
              messageText.length == 0
            "
            >{{ $t("components.sendMessageModal.send") }}</v-btn
          >
        </FooterModal>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../store";
import { resetSendMessageBeforeConferenceEvent, sendMessageBeforeConferenceStore } from "../../effector/modals";
import { newMessageEvent  } from "../../effector/message" 
import HeaderModal from "../modal/modalComponents/headerModal.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";

export default {
  components: { HeaderModal, FooterModal },
  props: [],
  data() {
    const effector = {
      showSendMessageBeforeConferenceModal: sendMessageBeforeConferenceStore,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      messageText: '',
      rules: [(v) => !!v || "", (v) => !!v.trim() || "Message can not be blank"],
      // Effector
      ...effector,
    }

  },
  mounted() {},
  unmounted() {},
  methods: {
    sendMessage(){
      // add logic to send messages
      const recipients = this.showSendMessageBeforeConferenceModal.users || [];
      if (recipients && recipients.length){
        let listTosend = recipients.map(e => e.uuid);
          if ( listTosend && listTosend.length ){
            listTosend.forEach(element => {
              if (element !== store.state.ownUUID){
                this.preparedataToSendMsgEffector(element,this.messageText) 
              } 
            });        
          }
      }
      this.closeModal()
    },
    closeModal() {
      resetSendMessageBeforeConferenceEvent();
    },
    preparedataToSendMsgEffector(id, text) {
      const userToSendMsg = {};
      userToSendMsg[id] = {
        userUUID: id,
        read: false,
        replied: false,
        deleted: false,
        viewed: false,
      };
      const dataMsg = {
        body: text,
        header: "individual message",
        date: Date.now(),
        users: userToSendMsg,
        isReply: false,
        creatorUUID: this.ownUUID,
        masterMessageUUID: null,
        parentMessageUUID: null,
        type: "message",
      };
      newMessageEvent(dataMsg);
    }
  },
};
</script>

<style scoped lang="scss">
</style>