<template>
  <div
    v-if="showEntry && entryForShow"
    class="timelineWrapper"
    :style="magicParentStyle"
    @click="handleEventEntry"
  >
    <v-tooltip bottom max-width="750">
      <template v-slot:activator="{ props }">
        <v-img
          v-on="on"
          class="mx-2 borderRadius5 cursorPointer eventEntryImage"
          @error="onImgError"
          :src="firstAvatar"
          contain
          lazy-src="assets/default_profile_picture.png"
          v-if="entry.eventType === 'directCall'"
        ></v-img>
        <v-btn
          :style="magicStyle"
          height="30"
          v-bind="props"
          class="btn customButton eventButton text-truncate"
        >
          <p
            :class="entry.eventType === 'directCall'? 'ml-5' : null"
            class="timeEntryHint"
          >{{isWaitingRoomUserWithDate? getTitelForUuid(entry.users[0]) + ' ' + getNameForUuid(entry.users[0]) : $t('generics.group')}}</p>
        </v-btn>
      </template>
      <span v-if="entry.color !== 'red'">
        <GeneralDetails v-if="!isWaitingRoomUserWithDate" :entry="entry" />
        <WaitingroomUserDetails v-if="isWaitingRoomUserWithDate" :entry="entry" />
        <OneCallDetails v-if="isOneCall" :entry="entry" />
        <AppointmentDetails v-if="isAppointment" :entry="entry" />
      </span>
      <span v-else v-html="getInCurseConferenceDetail"></span>
    </v-tooltip>
  </div>
</template>

<script>
import { useStore } from "effector-vue/composition";
import { setReminderModalEvent } from "../../effector/modals";
import { callPerson } from "../../utils/calls";
import GeneralDetails from "./eventDetails/generalDetails.vue";
import OneCallDetails from "./eventDetails/oneCallDetails.vue";
import AppointmentDetails from "./eventDetails/appointmentDetails.vue";
import store from "../../../websrc/store";
import { bridgeInfoStore } from "../../effector/users/bridgeInfo";
import { myGroupConferenceStore } from "../../effector/groupConferences";
import { myConferenceHallStore, myConferenceHallDetails } from "../../effector/conferenceHalls";
import { hasPrivilege } from "../../utils/privileges.js";
import {
  actingAsUuidStore,
} from "../../effector/representative";
import {
  dispatchViewedTimelineEntry
} from "../../effector/timeline";
import WaitingroomUserDetails from "./eventDetails/waitingroomUserDetails.vue";

export default {
  props: ["entry", "index", "totalEntries", "date"],
  components: {
    OneCallDetails,
    AppointmentDetails,
    GeneralDetails,
    WaitingroomUserDetails
  },
  data() {
    const effector = {
      bridgeInfoStore: bridgeInfoStore,
      myConferences: myGroupConferenceStore,
      myConferenceHalls: myConferenceHallStore,
      myConferenceHallDetails: myConferenceHallDetails,
      actingAsUuidStore: actingAsUuidStore
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      failedImage: false,
      showModalReminder: false,
      translatedHeader: '',
      // Effector
      ...effector,
    };
  },
  created() {
    this.translatedHeader = this.$t('components.conferences.conferenceStarted');
  },
  computed: {
    isWaitingRoomUserWithDate() {
      return (
        this.entry &&
        !this.entry.settings &&
        !this.entry.eventType &&
        this.entry.users.length == 1
      );
    },
    getInCurseConferenceDetail () {
      if (this.entry && this.entry.color === "red") {
        let usernames = [];
        let header = this.translatedHeader;
        let text = "";
        if (this.entry && this.entry.users) {
          this.entry.users.forEach(uuid => {
            const username =
              ((store.state.group[uuid] || {}).user || {}).name || "";
            if (username && username !== "") {
              let data = { username: username, uuid: uuid };
              usernames.push(data);
            }
          });
          usernames.forEach(name => {
            let isOnline = false;
            if (this.bridgeInfoStore && this.bridgeInfoStore.calls) {
              Object.keys(this.bridgeInfoStore.calls).forEach(call => {
                if (
                  this.bridgeInfoStore.calls[call].confId === this.entry.confId
                ) {
                  isOnline = this.bridgeInfoStore.users[name.uuid] || false;
                }
              });
            }
            if (isOnline) {
              text =
                text +
                '<div><p style=" margin: 0 auto;width: 12px;height: 12px;display:inline-block; background-color: #ABFF00; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 6px;"></p><p style="margin-left: 7px; margin-bottom: 0px; display:inline-block;">' +
                name.username +
                "</p></div>";
            } else {
              text =
                text +
                '<div><p style=" margin: 0 auto;width: 12px;height: 12px;display:inline-block; background-color: #f60c0c; border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #393938 0 -1px 9px, #f25454 0 2px 6px;"></p><p style="margin-left: 7px;display:inline-block; margin-bottom: 0px;">' +
                name.username +
                "</p></div>";
            }
          });
        }
        return "<p>" + header + "</p>" + text + "</div>";
      }
    },
    userHasViewedEvent() {
      // creators view
      if (this.entry && this.entry.creatorUUID === store.state.ownUUID)
        return true;

      const eventStatus =
        ((this.entry.viewed || {})[store.state.ownUUID] || {}).status || "none";

      if (eventStatus == "none" || eventStatus == "false") {
        return false;
      } else {
        return true;
      }
    },
    // blinkBefore(){
    //   return store.state.user.userSettings.minBeforeBlinkPlanerEvent
    // },
    // blickEvent(){
    //   if(this.startDate.getTime() > this.state.currentTS ){
    //     const difTimeMiliseconds = this.startDate.getTime() -  this.state.currentTS
    //     const beforeBlinkMiliSeconds = this.blinkBefore * 60000
    //     if(difTimeMiliseconds <= beforeBlinkMiliSeconds ) return true
    //     else return false
    //   }else{
    //     return false
    //   }
    // },
    isRsvpComplete() {
      if (!this.entry.users) return true;
      if (!this.entry.rsvp) return true;

      let isComplete = true;
      for (const userUUID of this.entry.users) {
        const rsvp = this.entry.rsvp[userUUID];
        if (rsvp && rsvp.status === "pending") isComplete = false;
      }
      return isComplete;
    },
    entryForShow() {
      if (
        this.entry.type !== "holiday" &&
        this.entry.type !== "homeOffice" &&
        this.entry.type !== "office" &&
        new Date(this.entry.start).getHours() < 19
      ) {
        return true;
      } else {
        return false;
      }
    },
    magicStyle() {
      return {
        border: "solid 2px !important",
        //borderColor: 'orange !important',//'#38a2b8 !important',
        width: "100%",
        height: "32px",
        //"37px",
        margin: "0px",
        // color: 'black',
        textTransform: "none",
        minWidth: "100%",
        backgroundColor: this.entry.confId ? this.entry.color : '#6dbbda' + "!important",
        borderColor: this.entry.confId ? this.entry.color : '#6dbbda' + "!important"
      };
    },
    magicParentStyle() {
      return {
        position: "absolute",
        // left: `calc(((100% / 13) - 15px) + ${this.leftStartRule}%)`,
        left: `calc(((100% / 12) +15px)%)`,
        right: this.rightEndRule + "%",
        width: 100 - this.rightEndRule - this.leftStartRule + "%",
        top: this.topRule
      };
    },
    topRule() {
      return 10 * this.index + "px";
    },
    leftStartRule() {
      const aDayMs = 60000 * 60 * 12;
      const aDayMsSplit = 100 / aDayMs;

      if (this.startDate.getDay() < this.dateActual.getDay()) return 0;

      const date = new Date(this.startDate);
      date.setHours(8, 0, 0, 0);

      const diff = this.startDate - date;
      let per = aDayMsSplit * diff;
      if (per < 0) per = 0;
      else if (per > 100) per = 100;
      return per;
    },
    rightEndRule() {
      const aDayMs = 60000 * 60 * 12;
      const aDayMsSplit = 100 / aDayMs;

      if (this.endDate.getDay() > this.dateActual.getDay()) return 0;

      const date = new Date(this.endDate);
      date.setHours(8, 0, 0, 0);

      const diff = this.endDate - date;
      let per = aDayMsSplit * diff;
      if (per < 0) per = 0;
      else if (per > 100) per = 100;
      return 100 - per;
    },
    startDate() {
      return new Date(this.entry.start);
    },
    endDate() {
      return new Date(this.entry.end);
    },
    dateActual() {
      return new Date(this.date);
    },
    showEntry() {
      return (
        !(this.rightEndRule === 100 && this.leftStartRule === 0) &&
        !(this.rightEndRule === 0 && this.leftStartRule === 100)
      );
    },
    isOneCall() {
      return this.entry.type === "oneCall";
    },
    isAppointment() {
      return this.entry.type === "appointment";
    },
    firstUser() {
      return (this.state.group[this.entry.users[0]] || {}).user || {};
    },
    firstAvatar() {
      const ownerAvatar =
        ((this.state.group[this.entry.creatorUUID] || {}).user || {}).avatar ||
        "img/default_profile_picture.png";
      if (this.failedImage) return "img/default_profile_picture.png";
      return ownerAvatar;
    },
    amInACall() {
      return (
        Object.keys(store.state.remoteStreams).length > 0 ||
        Object.keys(store.state.remoteBridgeStreams).length > 0
      ); // !!Object.keys(store.state.rtc).length;
    }
  },
  methods: {
    hasPrivilege(uuid) {
      return hasPrivilege(uuid);
    },
    getNameForUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelForUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getUserOnlineToCall(){
      let onlineNextUserId = null;
      const conferenceCallId = bridgeInfoStore.getState().conferences[this.entry.confId];
      const conferenceCall = conferenceCallId ? bridgeInfoStore.getState().calls[conferenceCallId] : undefined;
      if (conferenceCall) {
        conferenceCall.users.forEach(uuid => {
          if (hasPrivilege(uuid)){
            if(this.findIsOnline(uuid)){
              onlineNextUserId = uuid;
            }
          }
        });
      }
      return onlineNextUserId;
    },
    findIsOnline(uuid) {
      let found = false;
      const conferenceCallId = bridgeInfoStore.getState().conferences[this.entry.confId];
      const conferenceCall = conferenceCallId ? bridgeInfoStore.getState().calls[conferenceCallId] : undefined;
      if (conferenceCall) {
        found = conferenceCall.users.find(e => e === uuid);
      }
      return found;
    },
    getActualTimeEachMinute() {
      let actualDate = Date().now();
      setInterval(() => {
        actualDate = Date.now();
      }, 1000);
    },
    handleEventEntry() {
        if (
          this.entry &&
          (this.entry.viewed || false) &&
          this.entry.viewed[store.state.ownUUID]
        ) {
          if (this.entry.viewed[store.state.ownUUID].status === "false") {
            // update field here
            dispatchViewedTimelineEntry([this.entry.uuid, true]);
          }
        }
        if (
          this.entry.color == "red" &&
          this.entry.creatorUUID != this.state.ownUUID &&
          !this.amInACall
        ) {
          callPerson(this.entry.creatorUUID, this.$router, true);
        } else if (
          this.entry.color == "red" &&
          this.entry.creatorUUID === this.state.ownUUID &&
          !this.amInACall
        ) {
          const uuid = this.getUserOnlineToCall();
          if (uuid) {
            callPerson(uuid, this.$router, false);
          }
        } else {
          if ((this.myConferences && this.myConferences.length) ||(this.myConferenceHallDetails && this.myConferenceHallDetails.length)){
            if(this.myConferences.find(e => e.confId === this.entry.confId) || this.myConferenceHallDetails.find(e => e.confId === this.entry.confId)){
              this.$router.push({ path: `/newconference/${this.entry.confId}` });
            } else {
              if(this.actingAsUuidStore && this.actingAsUuidStore == this.entry.creatorUUID){
                this.$router.push({ path: `/newconference/${this.entry.confId}` });
              } else {
                setReminderModalEvent(this.entry);
              }
            }
          }
        }
    },
    onImgError(event) {
      this.failedImage = true;
    },
    getColor(entry) {}
  }
};
</script>

<style lang="scss" scoped>
.ttparticipant {
  margin-bottom: 0px;
}
.ttparticipantOn {
  font-weight: bold;
  color: rgb(0, 128, 0);
  margin-bottom: 0px;
}
.eventButton {
  margin-top: 5px;
  font-size: 12px !important;
  z-index: 99;
  // background-color: var(--v-primary-lighten1) !important;
  // background-color: orange !important;
  // &.customButton{
  //   border: 2px solid orange !important;
  // }
  color: white !important;
}

.crosshatch {
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.1) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 0, 0.1) 75%,
    transparent 75%,
    transparent
  );
  background-size: 5px 5px;
  background-repeat: repeat;
}

.eventEntryImage {
  max-height: 40px;
  max-width: 25px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: -4px;
  z-index: 100;
  top: 8px;
}

.timeEntryHint {
  overflow-x: hidden;
  // margin-top: 18px;
}
// .entryEventBlink {
//   animation: blink 1s linear infinite;
//   z-index: 9;
//   //  pointer-events: none;
// }
</style>