<template>
  <v-card
    class="mx-auto elevation-0 primaryBackground"
    flat
    max-width="100%"
    variant="outlined"
    v-if="validPerson"
  >
    <div class="iconCardsContainerLeft" v-if="person.uuid !== ownUUID && section !== 'guests' && person.connected">
      <MultiUserCallIconGrid :person="person"/>
    </div>
    <UserProfle
      v-if="showUserProfile"
      :showUserProfile="showUserProfile"
      :toggleUserProfile="toggleUserProfile"
    />
    <v-divider class="mt-1"></v-divider>
    <v-list-item three-line class="py-2 primaryBackground">
      <v-tooltip location="top" :disabled="disabledUserInfoTT">
        <template v-slot:activator="{ props }">
          <v-avatar
            tile
            v-bind="props"
            size="80"
            :class="`borderRadius5 mx-2 my-0 ${
              !person.connected ? 'grayScale' : ''
            }`"
          >
            <!-- !person.connected && !isAiUser(person.user?.uuid) ? 'grayScale' : '' -->
            <DefaultAvatar
              :size="80"
              :userData="person.user"
              v-if="avatarSrc === 'img/default_profile_picture.png'"
              :setUserInModal="setUserInModal"
            ></DefaultAvatar>
            <v-img
              v-if="avatarSrc !== 'img/default_profile_picture.png'"
              @click="setUserInModal()"
              class="mx-2 borderRadius5 cursorPointer"
              @error="onImgError"
              :src="avatarSrc"
              max-height="80"
              max-width="80"
              contain
              lazy-src="assets/default_profile_picture.png"
            ></v-img>
          </v-avatar>
        </template>
        {{ (person.user.visitorData || {}).noteForContact }}
      </v-tooltip>
      <div class="contentWrapper">
        <div class="notranslate text-white mb-3">
          {{ getUserTitel(person.uuid) }}
          {{ userName }}
        </div>    
        <v-list-item-subtitle
          class="userActivity mb-3 userInCall text-white"
          v-if="getCallText(person) && person.user.activity !== 'No status'"
        >
          <font-awesome-icon :icon="['fal', 'video']" />
          {{ getCallText(person) }}
        </v-list-item-subtitle>
        <v-list-item-subtitle
          class="userActivity mb-3 userInCall d-block text-truncate text-white"
          v-if="
            !getCallText(person) &&
            !getUserIsGuest(person.uuid || person.user?.uuid)
          "
          >{{ userPosition }}</v-list-item-subtitle
        >
        <v-list-item-subtitle
          class="userActivity mb-3 userInCall d-block text-truncate my-0 text-white"
          v-if="getUserIsGuest(person.uuid || person.user?.uuid)"
        >
          <p class="my-0">
            {{ moment(person.user.created).format("DD.MM.YYYY HH:mm") }}
          </p>
          <p class="my-0" v-if="person.user.paylinkInfo">
            {{ $t("generics.paylink") }}: {{ person.user.paylinkInfo.amount }}
          </p>
        </v-list-item-subtitle>
        <v-list-item-subtitle
          :style="{ color: badgetClassObj }"
          v-if="state.namespaceSettings.displayUserStatus"
        >
          <span>{{ checkStatusLabel }}</span>
        </v-list-item-subtitle>
      </div>
    </v-list-item>
    <v-tooltip location="top" max-width="250px">
      <template v-slot:activator="{ props }">
        <v-card
          v-bind="props"
          :color="badgetClassObj"
          class="px-2 noBRadius"
          :style="{
            borderWidth: 1,
            borderColor: borderClass,
            borderStyle: 'solid',
            height: 36,
          }"
          v-if="!isGuest"
          min-height="36"
        >
          <v-row align="center" justify="space-between" class="mx-0 customWidh pt-3">
            <v-badge
              bordered
              color="error"
              :content="
                state.namespaceSettings.publicMsgs
                  ? person.user.unreadMessageCounter === 0 ||
                    !person.user.unreadMessageCounter
                    ? null
                    : person.user.unreadMessageCounter
                  : null
              "
              :model-value="
                state.namespaceSettings.publicMsgs
                  ? person.user.unreadMessageCounter === 0 ||
                    !person.user.unreadMessageCounter
                    ? null
                    : person.user.unreadMessageCounter
                  : null
              "
              overlap
            >
              <v-tooltip location="right">
                <template v-slot:activator="{ props }">
                  <v-btn
                    v-bind="props"
                    icon
                    density="comfortable"
                    variant="text"
                    :color="wholeBarWithColors() ? 'white' : 'black'"
                    :disabled="person.user?.uuid == ownUUID || isAiUser(person.user?.uuid)"
                    @click="setShowModalSenMsg(person.user?.uuid, 'message')"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'paper-plane']"
                    />
                      <!-- :style="{ fontSize: '16px' }" -->
                  </v-btn>
                </template>
                <span>{{ $t("components.userProfile.sendAMessage") }}</span>
              </v-tooltip>
            </v-badge>
            <v-tooltip
              location="right"
              v-if="!isInCallWithUs && person.user?.uuid!==ownUUID"
              :disabled="person.user.activity === 'No Calls'"
            >
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon variant="text"
                  density="comfortable"
                  :color="phoneColorColor"
                  v-bind:disabled="disableCallBtn"
                  v-on:click="callUser(person.user?.uuid)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'phone']"
                    v-if="!wholeBarWithColors()"
                  />
                  <font-awesome-icon
                    :icon="['fal', 'phone']"
                    v-else
                  />
                </v-btn>
              </template>
              <span>{{
                typeData == "waitingroom"
                  ? $t("components.userListItem.videoCallStartWr")
                  : $t("components.userListItem.videoCallStart")
              }}</span>
            </v-tooltip>
            <v-tooltip location="right" v-if="!isMobile && typeData !== 'waitingroom' && showOrganigram">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon variant="text"
                  density="comfortable"
                  :disabled="isAiUser(person.user?.uuid)"
                  :color="wholeBarWithColors() ? 'white' : 'black'"
                  @click.stop.prevent="goToOrganisation(person.uuid)"
                >
                  <!-- :disabled="!isUserInOrganigram" -->
                  <font-awesome-icon
                    :icon="['fal', 'sitemap']"
                  />
                </v-btn>
              </template>
              <span>{{
                $t("components.organisationCarousel.showInOrganigram")
              }}</span>
            </v-tooltip>
            <v-tooltip location="right" v-if="!isMobile && !isBasicView && showUploadFile">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  icon variant="text"
                  density="comfortable"
                  :color="wholeBarWithColors() ? 'white' : 'black'"
                  v-bind:disabled="disableSendFileBtn || isAiUser(person.user?.uuid)"
                  @click="sendFileTo(person.user?.uuid)"
                >
                  <font-awesome-icon
                    :icon="['fal', 'upload']"
                  />
                </v-btn>
              </template>
              <span>{{ $t("generics.uploadFile") }}</span>
            </v-tooltip>
              <!-- v-if="!isAiUser(person.user?.uuid)" -->
            <UserMoreActions
              :isRegularGrid="false"
              :person="person"
              :section="section"
              :customStyle="true"
              :typeData="typeData"
            />
          </v-row>
        </v-card>
      </template>
      <span>
        <UserInfoTooltip :person="person"></UserInfoTooltip>
      </span>
    </v-tooltip>
    <v-card
      :color="badgetClassObj"
      class="px-2 noBRadius"
      v-if="isGuest"
      :style="{
        borderWidth: 1,
        borderColor: borderClass,
        borderStyle: 'solid',
        height: 36,
      }"
    >
      <v-row align="center" justify="space-between" class="mx-0 customWidh">
        <v-tooltip
          location="right"
          v-if="!isInCallWithUs && person.user?.uuid!==ownUUID"
          :disabled="person.user.activity === 'No Calls'"
        >
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              icon variant="text"
              density="comfortable"
              :color="phoneColorColor"
              v-bind:disabled="disableCallBtn"
              v-on:click="callUser(person.user?.uuid)"
            >
              <font-awesome-icon
                :icon="['fas', 'phone']"
                v-if="!wholeBarWithColors()"
              />
              <font-awesome-icon
                :icon="['fal', 'phone']"
                v-else
              />
            </v-btn>
          </template>
          <span>{{ $t("components.userListItem.videoCallStart") }}</span>
        </v-tooltip>
        <v-badge
          bordered
          color="error"
          :content="
            state.namespaceSettings.publicMsgs
              ? person.user.unreadMessageCounter === 0 ||
                !person.user.unreadMessageCounter
                ? null
                : person.user.unreadMessageCounter
              : null
          "
          :value="
            state.namespaceSettings.publicMsgs
              ? person.user.unreadMessageCounter === 0 ||
                !person.user.unreadMessageCounter
                ? null
                : person.user.unreadMessageCounter
              : null
          "
          overlap
        >
          <v-tooltip location="right">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                icon variant="text"
                :color="wholeBarWithColors() ? 'white' : 'black'"
                @click="setShowModalSenMsg(person.uuid, 'message')"
              >
                <font-awesome-icon
                  :icon="['fal', 'paper-plane']"
                />
              </v-btn>
            </template>
            <span>{{ $t("components.userProfile.sendAMessage") }}</span>
          </v-tooltip>
        </v-badge>
        <v-tooltip location="right" v-if="!isMobile && !isBasicView && showUploadFile">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              icon variant="text"
              density="comfortable"
              :color="wholeBarWithColors() ? 'white' : 'black'"
              v-bind:disabled="disableSendFileBtn"
              @click="sendFileTo(person.user?.uuid)"
            >
              <font-awesome-icon
                :icon="['fal', 'upload']"
              />
            </v-btn>
          </template>
          <span>{{ $t("generics.uploadFile")}}</span>
        </v-tooltip>
      </v-row>
    </v-card>
  </v-card>
</template>

<script>
import store, { EventBus } from "../../store";
import { isMobile } from "../../lib/mobileUtil";
import { getWebrtcConnections, sendFileTo } from "../../lib/rtcConn";
import UserProfle from "../modal/userProfile.vue";
import moment from "../../../sharedsrc/moment";
import DefaultAvatar from "../image/defaultAvatar.vue";
import UserInfoTooltip from "../content/userInfoTooltip.vue";
import UserMoreActions from "../content/userMoreActions.vue";
import { callPerson, disableCallBtn, userInCallText } from "../../utils/calls";
import { getStatusColorByUser } from "../../utils/status";
import { basicView } from "../../utils/privileges";
import { wholeBarWithColor } from "../../utils/color";
import { setHistoryMessagesModalEvent } from "../../effector/modals";
import { userHasHolidaysSetted, userHasIllnessSetted, getUserActivityFunction,
  checkIsDateInRange, canIShowOrganigram, userInOrganigram, isAiUser } from "../../utils/basicFunctions";
import MultiUserCallIconGrid from "../content/multiUserCallIconGrid.vue";

const hasOwn = Object.prototype.hasOwnProperty;

export default {
  props: ["person", "section", "typeData"],
  components: {
    UserProfle,
    DefaultAvatar,
    UserInfoTooltip,
    UserMoreActions,
    MultiUserCallIconGrid
  },
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      showUserProfile: false,
      failedImage: false,
      clickStaff: {
        delay: 700,
        clicks: 0,
        timer: null,
      },
      isMobile: isMobile(),
      showOrganigrammFor: store.state.namespaceSettings.featureOrganization,
    };
  },
  methods: {
    isAiUser(uuid){
      return isAiUser(uuid)
    },
    checkUserIsDateInRange(startDate, endDate, date) {
      return checkIsDateInRange(startDate, endDate, date);
    },
    getUserActivity(person) {
      return getUserActivityFunction(person)
    },
    getUserTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
    getCallText(person) {
      return userInCallText(person);
    },
    wholeBarWithColors() {
      return wholeBarWithColor();
    },
    goToOrganisation(uuid) {
      // open large user if you click again in the same button to open #3985
      if (this.$route.path == ('/organisation/' + uuid) && this.$router.params?.uuid) {
        EventBus.$emit("showLargeUserOrganisation", this.$router.params.uuid);
      }
      // go to the user organisation
      this.setCurrentContentVisile("organisation/" + uuid, true, this.$router);
    },
    callUser(uuid) {
      this.clickStaff.clicks++;
      if (this.clickStaff.clicks === 1) {
        this.clickStaff.timer = setTimeout(() => {
          callPerson(uuid, this.$router);
          this.clickStaff.clicks = 0;
        }, this.clickStaff.delay);
      } else {
        clearTimeout(this.timer);
        this.clickStaff.clicks = 0;
      }
    },
    getUserIsGuest(uuid) {
      return store.getUserIsGuest(uuid);
    },
    sendFileTo(personUuid) {
      let uuid = personUuid;
      return sendFileTo(uuid);
    },
    toggleUserProfile() {
      this.showUserProfile = !this.showUserProfile;
    },
    setCurrentContentVisile(...args) {
      return store.setCurrentContentVisile(...args);
    },
    setUserInModal() {
      //open modal if im not guest
      if (!this.isGuest) {
        store.state.ownModalUser = this.person.uuid === this.state.ownUUID;
        store.state.userOnModal = { ...this.person.user };
        store.state.userOnModal.connected = this.person.connected;
        store.state.userOnModal.uuid = this.person.uuid;
        store.state.userOnModal.userTS = this.person.userTS;
        this.toggleUserProfile();
      }
    },
    moment(...args) {
      return moment(...args);
    },
    onImgError() {
      this.failedImage = true;
    },
    setShowModalSenMsg(uuid) {
      setHistoryMessagesModalEvent(uuid);
    },
    getWebrtcConnections() {
      return getWebrtcConnections();
    },
    userActivityTranslated(activity) {
      const key = `status.${activity}`;
      const translation = this.$t(key) || key;
      return key === translation ? `${activity}` : translation;
    },
  },
  computed: {
    breakTimeEnd() {
      if ((this.person.user.breakTimeEnd || '').length > 5 && Date.parse(this.person.user.breakTimeEnd)) {
        return moment(this.person.user.breakTimeEnd).format("HH:mm");
      }
      return this.person.user.breakTimeEnd;
    },
    checkStatusLabel() {
      // if (
      //   this.person.user.activity === "Break" &&
      //   this.person.user.breakTimeEnd
      // ) {
      //   return this.$t("generics.expectedReturn");
      // } else {
      if (this.getPersonInHolidays) {
        return this.$t('status.Holidays');
      } else if (this.getPersonInIllness) {
        return this.$t('status.Ill');
      } else {
        return this.getUserActivity(this.person);
      }
      // }
    },
    getPersonInHolidays() {
      return userHasHolidaysSetted(this.person.user.uuid);
    },
    getPersonInIllness() {
      return userHasIllnessSetted(this.person.user.uuid);
    },
    showHolidaysInfo() {
      if((this.getUserActivity(this.person) === this.$t('status.Holidays') || !this.person.connected) && !this.isGuest &&
          (this.person.user.startHolidays &&
          this.person.user.endHolidays &&
          this.checkUserIsDateInRange(new Date(this.person.user.startHolidays),new Date(this.person.user.endHolidays),new Date()))
        ) {
          return true;
      }
      return false
    },
    disabledUserInfoTT() {
      if (this.typeData !== 'waitingroom') return true;
      if (this.typeData === 'waitingroom' && this.person.user.visitorData && (this.person.user.visitorData.noteForContact === undefined || this.person.user.visitorData.noteForContact === '' )) return true
      return false;
    },
    isUserInOrganigram() {
      return userInOrganigram(this.person.uuid);
    },
    showOrganigram() {
      return canIShowOrganigram();
    },
    isBasicView() {
      return basicView();
    },
    borderClass() {
      return getStatusColorByUser(this.person.user, this.person.connected);
    },
    userList() {
      return store.getProcessedUserList();
    },
    getUserTeams() {
      const teams = store.state.namespaceSettings.processedOrganisation.teams;
      let userTeams = [];
      for (const team in teams) {
        if (hasOwn.call(teams, team)) {
          const element = teams[team];
          element.members.forEach((member) => {
            if (member === this.person.uuid) {
              userTeams.push(" " + team);
            }
          });
        }
      }
      userTeams = userTeams.toString();
      return userTeams;
    },
    isInCallWithUs() {
      return (
        !!this.state.rtc[this.person.uuid] &&
        !!this.state.remoteStreams[this.person.uuid]
      );
    },
    isGuest() {
      return store.getUserIsGuest(this.state.ownUUID);
    },
    phoneColorColor() {
      if (this.wholeBarWithColors()) {
        return "#ffffff";
      } else {
        return getStatusColorByUser(this.person.user, this.person.connected);
      }
    },
    badgetClassObj() {
      if (this.wholeBarWithColors()) {
        return getStatusColorByUser(this.person.user, this.person.connected);
      } else {
        return "rgba(255,255,255,0.3)";
      }
    },
    disableSendFileBtn() {
      let person = this.person;
      person = this.person;
      return !!(!person || !person.connected || person.user?.uuid == this.ownUUID);
    },
    disableCallBtn() {
      return disableCallBtn(this.person);
    },
    avatarSrc() {
      if (this.failedImage) return "img/default_profile_picture.png";
      return this.person.user.avatar || "img/default_profile_picture.png";
    },
    userName() {
      return this.person.user.name || "Anonymous";
    },
    userPosition(){
      return this.person.user.position;
    },
    validPerson() {
      return (
        this.person &&
        this.person !== "" &&
        this.person.user &&
        this.person.user.name
      );
    },
    showUploadFile() {
      return this.state.namespaceSettings.featureFileTranfer;
    }
  },
};
</script>

<style lang="scss">
  .v-list-item-subtitle{
    opacity: 1 !important;
    font-weight: 300 !important;
  }
</style>
<style scoped lang="scss">
.customWidh {
  width: 89%;
}
.contentWrapper {
  max-width: 180px;
}
.noBRadius {
  border-radius: 0px !important;
}
.borderRadius5 {
  border-radius: 5px !important;
}
.cursorPointer {
  cursor: pointer;
}
.v-application--is-ltr .v-list-item__avatar:last-of-type:not(:only-child) {
  margin-left: 0px !important;
}
.v-card .v-list-item {
  padding-left: 4px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
  border-radius: 0px !important;
}
.grayScale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.iconCardsContainerLeft {
  position: absolute;
  top: 8px;
  left: 8px;
  background: unset;
  height: 28px;
  z-index: 1;
}
.fontSize12 {
  font-size: 12px;
}
</style>

