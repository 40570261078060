<template>
 <v-dialog
    :model-value="showModal"
    @update:model-value="showModal = $event" persistent max-width="560">
    <div>
      <v-card class="mx-auto">
        <!-- Title -->
        <HeaderModal
          :titleModal="$t('components.conferenceForm.titleInviteVisitor')"
          :closeModalFunction="closeModal"
        />
        <!-- End title -->
        <!-- Modal body -->
        <v-card-text class="px-2">
          <v-row class="mx-0 pt-6">
            <v-col cols="7" class="py-0">
              <v-combobox
                :model-value="contactSelected" @update:model-value="contactSelected = $event; handleSelectContact($event)"
                :items="filteredInvitations"
                class="pb-6"
                variant="outlined"
                item-title="name"
                item-value="email"
                hide-details="auto"
                :label="$t('components.inviteVisitor.contacts')"
                select
                density="compact"
                :menu-props="{ closeOnContentClick: true }"
              >
                <template slot="item" slot-scope="data">
                  <v-list-item
                    @click="handleSelectContact(data.item)"
                    style="position: relative"
                  >
                    <div>
                      <v-list-item-title
                        class="listTitle"
                        v-text="data.item.name"
                      >
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="listSubtitle"
                        v-text="data.item.email"
                      ></v-list-item-subtitle>
                    </div>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="5" class="py-0">
              <v-btn
                align="right"
                class="cursorPointer heightBtn40w100"
                color="primary"
                variant="outlined"
                @click.stop.prevent="saveContact()"
                :disabled="!name || name.length == 0 || isEmailInvalid"
              >
                {{ $t("components.inviteGuestPanel.saveContact") }}
              </v-btn>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                @blur="applyRules = true"
                :model-value="name" @update:model-value="name = $event"
                counter
                maxlength="60"
                required
                variant="outlined"
                :rules="applyRules ? userNameRules : noRules"
                :label="$t('components.inviteVisitor.nameGuest')"
                density="compact"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                :model-value="email" @update:model-value="email = $event"
                :label="$t('components.inviteVisitor.invitationEmail')"
                required
                variant="outlined"
                density="compact"
                :rules="emailRules"
                @keyup="changeEmail"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="py-0">
              <vue-tel-input
                :model-value="telNumberModel"
                @update:model-value="telNumberModel = $event"
                defaultCountry="DE"
                enabledCountryCode
                @validate="onTelInput"
                name="number"
                id="numDisplay"
                class="
                  focusStyle
                  form-control
                  text-center
                  input-sm
                  inputPhone2FA
                "
                value
                :inputOptions="{placeholder: ''}"
                autocomplete="off"
                :class="{ borderError: !phoneInputValid }"
              ></vue-tel-input>
            </v-col>
            <v-col cols="12" v-if="isConference" class="py-0">
              <v-checkbox
                :model-value="showUserName" @update:model-value="showUserName = $event"
                :label="$t('components.conferenceForm.showVisitorName')"
                class="mt-0 fontSize14 mb-0"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="position: relative" class="py-0">
              <v-text-field
                :model-value="userFunction" @update:model-value="userFunction = $event"
                class="functionFieldPadd"
                variant="outlined"
                density="compact"
                hide-details="auto"
                :label="$t('components.inviteVisitor.notiz')"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- End modal body -->

        <!-- Footer -->
        <FooterModal :hideDefaultButtons="true">
            <!-- style="color: #11889d !important" -->
          <v-btn
            color="white"
            
            class="mr-3"
            :disabled="!isNotAbleToAddUser"
            @click="saveMember(true)"
          >
            {{ $t("components.addGroupModal.add") }}
          </v-btn>
            <!-- style="color: #11889d !important" -->
          <v-btn
            color="white"
            class="mr-1"
            :disabled="!isNotAbleToAddUser"
            @click="saveMember(false)"
          >
            {{ $t("components.addGroupModal.addplus") }}
          </v-btn>
        </FooterModal>
        <!-- End footer -->
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { useStore } from "effector-vue/composition";
import store from "../../../store";
import HeaderModal from "../../modal/modalComponents/headerModal.vue";
import FooterModal from "../../modal/modalComponents/footerModal.vue";
import { v4 as uuidv4 } from "uuid";
import {
  findUserEmailEvent,
  foundExistingEmailState,
} from "../../../effector/users";
import {
  dispatchSuccessAlert,
  dispatchErrorAlert,
} from "../../../effector/alerts";
import { createVisitor } from "../../../lib/inviteHelpers";
export default {
  components: { HeaderModal, FooterModal },
  props: [
    "showModal",
    "closeModal",
    "isConference",
    "members",
    "addMember",
    "conferenceId",
  ],
  data() {
    const effector = {
      foundExistingEmail: foundExistingEmailState,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      contactSelected: null,
      name: "",
      email: "",
      errMssg: false,
      applyRules: true,
      noRules: [],
      emailValid: false,
      telNumberModel: "",
      phoneNumberRegister: "",
      dialCode: "",
      phoneInputValid: true,
      userFunction: "",
      editingFunction: false,
      showUserName: true,
      // Effector
      ...effector,
    }
  },

  methods: {
    changeEmail() {
      this.email = this.email.trim().toLowerCase();
    },
    edit() {
      this.editingFunction = true;
    },
    editFunction() {
      if (this.userFunction !== "") {
        this.editingFunction = false;
        dispatchSuccessAlert(this.$t("components.inviteVisitor.functionSaved"));
      }
    },
    onTelInput({ number, valid, country } = {}) {
      const data = { number, valid, country };
      if (this.telNumberModel.length > 0) {
        if (data.number) {
          // new number
          if (data.valid) {
            this.dialCode = data.country ? "+" + data.country.dialCode : "";
            this.phoneNumberRegister = data.number.replace(/[-\s]/g, "");
          } else {
            this.dialCode = "";
            this.phoneNumberRegister = "";
          }
          this.phoneInputValid = data.valid;
        } else {
          // number from the saved list
          this.phoneNumberRegister = (this.phoneNumberInput || "").replace(
            /\s/g,
            ""
          );
          this.telNumberModel = this.phoneNumberRegister;
          this.phoneInputValid = true;
        }
      } else {
        this.dialCode = "";
        this.phoneNumberRegister = "";
        this.phoneInputValid = true;
      }
    },
    async checkEmailPreSend() {
      const email = this.email.trim().toLowerCase();
      const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/;
      if (!re.test(email)) {
        this.emailValid = false;
        this.errMssg = true;
      } else {
        this.errMssg = false;
        this.emailValid = true;
      }
    },
    handleSelectContact(item) {
      if (item) {
        this.contactSelected = item;
        this.name = item.name;
        this.email = item.email;
        if (this.errMssg) {
          this.errMssg = false;
        }
        // }
      }
    },
    isValidSearch(term) {
      if (term !== "") {
        // const email = term.trim().toLowerCase();
        const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/;
        return re.test(term);
      } else {
        return false;
      }
    },
    async saveContact() {
      const existingVideoCallInvitations =
        store.state.user.videoCallInvitations;
      let extantEmail = Object.keys(store.state.group).filter(
        (uuid) =>
          store.state.group[uuid] &&
          store.state.group[uuid].user &&
          !store.state.group[uuid].user.visitor &&
          store.state.group[uuid].user.email === this.email
      );
      if (extantEmail && extantEmail.length) {
        dispatchErrorAlert(
          this.$t("components.inviteGuestPanel.errAlreadyUser")
        );
        return;
      }
      if (this.isValidSearch(this.email)) {
        await findUserEmailEvent(this.email);
        if (this.foundExistingEmail.length === 0) {
          const emailAlreadyExists = existingVideoCallInvitations.findIndex(
            (e) => e.email === this.email
          );
          if (
            emailAlreadyExists !== -1 &&
            this.name !== existingVideoCallInvitations[emailAlreadyExists].name
          ) {
            existingVideoCallInvitations.splice(emailAlreadyExists, 1);
          } else if (
            emailAlreadyExists !== -1 &&
            this.name === existingVideoCallInvitations[emailAlreadyExists].name
          ) {
            return;
          }
          const newItem = {
            name: this.name,
            email: this.email,
          };
          existingVideoCallInvitations.push(newItem);
          store.setVideoCallInvitations(existingVideoCallInvitations);
          dispatchSuccessAlert(this.$t("components.inviteVisitor.saveSuccess"));
        }
      }
    },
    async handleNewVisitor() {
      const hresults = Object.values(store.state.persisted.userVisitors).filter(
        (entry) => entry && entry.email === this.email
      );
      if (hresults.length > 0) {
        return hresults[0];
      } else {
        const odata = {
          visitorId: uuidv4(),
          name: this.name,
          email: this.email || "",
          mobilePhone: this.phoneNumberRegister || "",
          noteForContact: this.userFunction,
          created: Date.now(),
          inviters: {
            [store.state.ownUUID]: true,
          },
          userInviter: store.state.ownUUID,
          conferenceUUID: [this.conferenceId],
          isWaitingRoom: true,
          hasEmailError: false,
        };
        await createVisitor(odata);
        store.setUserVisitor(odata.visitorId, odata);
        return odata;
      }
    },
    async saveMember(closeModal) {
      let extantEmail = Object.keys(store.state.group).filter(
        (uuid) =>
          store.state.group[uuid] &&
          store.state.group[uuid].user &&
          !store.state.group[uuid].user.visitor &&
          store.state.group[uuid].user.email === this.email
      );
      if (extantEmail && extantEmail.length) {
        dispatchErrorAlert(
          this.$t("components.inviteGuestPanel.errAlreadyUser")
        );
        return;
      }
      const visitor = await this.handleNewVisitor();
      const memberObj = {
        name: this.name,
        uuid: visitor.visitorId,
        isGuest: true,
        isModerator: false,
        userFunction: this.userFunction,
        showUserName: this.showUserName,
        hasEmailError: false,
        isPodium: false
      };
      if (this.addMember) {
        let existInMembers = false;
        if (this.members.find((e) => e.uuid === memberObj.uuid)) {
          existInMembers = true;
        }
        if (!existInMembers) {
          this.addMember(memberObj);
        }
      }
      if (closeModal) {
        this.closeModal();
      }
      this.resetAddGuestModal();
    },
    resetAddGuestModal() {
      this.contactSelected = null;
      this.name = "";
      this.email = "";
      this.emailValid = true;
      this.telNumberModel = "";
      this.phoneNumberRegister = "";
      this.dialCode = "";
      this.phoneInputValid = true;
      this.userFunction = "";
      this.editingFunction = false;
    },
  },
  computed: {
    isNotAbleToAddUser() {
      return (
        this.name !== "" &&
        ((this.email !== "" && !this.isEmailInvalid) ||
          (this.telNumberModel !== "" && this.phoneInputValid))
      );
    },
    userNameRules() {
      return [
        (v) => (!!v && !!v.trim().length) || this.$t("generics.nameRequired"),
      ];
    },
    emailRules() {
      return [
        (v) =>
          (!!v && !!v.trim().length) || this.$t("generics.emailMustBeValid"),
        (v) => /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/.test(v) || this.$t("generics.emailMustBeValid"),
        (v) =>
          (!!v && !v.includes(",")) || this.$t("generics.emailMustBeValid"),
        (v) =>
          (!!v && !v.includes(";")) || this.$t("generics.emailMustBeValid"),
      ];
    },
    phoneRules() {
      return [
        (v) => !!v || "",
        (v) => /^\+\d+$/.test(v) || this.$t("generics.phoneMustBeValid"),
      ];
    },
    filteredInvitations() {
      const myInvitations = store.state.user.videoCallInvitations || [];
      return myInvitations.filter((t) => {
        if (t.email) return t;
      });
    },
    isEmailInvalid() {
      if (this.email && this.email.length > 0) {
        const email = this.email.trim().toLowerCase();
        if (email.includes(",") || email.includes(";")) {
          return true;
        }
        const re = /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/;
        return !re.test(email);
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.saveFunctionButton {
  position: absolute;
  right: 21px;
  top: 2px;
}
.functionFieldPadd {
  padding-right: 40px;
}
</style>