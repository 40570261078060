<template>
  <div :class="!isChildren ? 'orgaPosition' : ''">
    <div class="tree-row" :class="'top-left'" :style="{ zoom: `${defaultZoomLevel}%` }">
      <div v-for="node in items" :key="node.id" class="tree-node"  :id="`tree-node-${node.id}`">
        <div :class="[getPersonAssistantsByUuids(
                    node.supervisors,
                    node.assistants
                  ).length ? 'node-content-Assisntants': currentZoomLevel>100 ? 'node-content-more-100' : 'node-content']"
                  @mouseenter="onNodeMouseEnter(node.id)"
                  @mouseleave="onNodeMouseLeave">
          <!-- name section -->
          <v-tooltip location="top">
            <template v-slot:activator="{ props }">
              <div v-bind="props" class="rounded-t-lg bg-white paddingSectionName text-h5 font-weight-bold text-truncate">{{ node.name }}</div>
            </template>
            <span>{{ node.name }}</span>
          </v-tooltip>
          <!-- name section -->
          <div :class="[node.supervisors.length > 2 ? 'py-2': 'py-2']" class="d-flex heightContent">
            <div class="d-flex px-2 w-100 flex-column justify-space-between" :class="[node.supervisors.length ==2 ? 'gap5': '', node.supervisors.length >=3 ? 'gap4': '']">
            <div class="rounded-lg position-relative w-100 align-content-center" :class="[getBorderStyleSupervisor(node.supervisors, index)]"
              v-for="(supervisor, index) in node.supervisors" :key="(node.id || node.uuid) + 'supervisor' + supervisor"
              :style="getBorderCard(node.supervisors, supervisor, index)">
              <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                  <div v-bind="props" :class="node.supervisors.length > 1? 'verticalDivider' : ''">
                    <div :class="[showUserPicture ? 'dotsPosition':'dotsPositionNoPicture', node.supervisors.length === 1 && showUserPicture ? 'bg-white' : '']" class="py-2">
                      <ButtonsFrontCard style="justify-items: end;" :componentName="'fullOrga'" :isOrga="true" :person="getPersonByUuid(supervisor)" :uuid="supervisor"
                        :isRegularGrid="true" @click.stop.prevent />
                    </div>
                    <v-img v-if="showUserPicture" :max-width="getImageSize('maxWidth', node.supervisors.length)" :height="getImageSize('height', node.supervisors.length)" :width="getImageSize('width', node.supervisors.length)"
                      :src="getAvatarForUuid(supervisor)"
                      :class="[appearInAHigherSection(supervisor, node) ? 'grayScale' : '', node.supervisors.length > 1 ? '' :'align-end ma-auto mt-7 rounded']" cover loading="lazy"
                      :lazy-src="getDefaultPicture">
                    </v-img>
                    <div class="align-center d-flex text-truncate">
                      <div class="flex-column w-100 d-flex text-truncate">
                        <div :class="[node.supervisors.length > 1 ? '': 'pt-1', node.supervisors.length == 3 ? 'text-caption lineHeigth3user' : '', node.supervisors.length == 4 ? 'text-caption lineHeigth4user' : '']" v-if="showUserPicture" class="px-2 text-truncate text-left font-weight-bold userNameContainer text--black">
                          {{ getPositionForUuid(supervisor) }}
                        </div>
                        <div :class="[showUserPicture ? 'text-caption text-truncate' : 'text-h5 multi-line-truncate',node.supervisors.length == 3 ? 'text-caption lineHeigth3user': '', node.supervisors.length == 4 ? 'text-caption lineHeigth4user' : '']" class="px-2 text-left userNameContainer text--black">
                          {{ getTitelByUuid(supervisor) }}
                          {{ getNameByUuid(supervisor) }}
                        </div>
                      </div>
                    </div>
                    <!-- <div class="align-center d-flex flex-row justify-space-between"
                      v-if="hasRepresentative(supervisor)"
                      :style="getBorderByStatusFull(getRepresentativeByUuid(supervisor))">

                      <div class="d-flex flex-row align-center" style="max-width: 85%">
                        <CallIcon v-if="!disableCallBtn(getRepresentativeByUuid(supervisor))"
                          :person="getRepresentativeByUuid(supervisor)" :isRegularGrid="true" :ownUUID="ownUUID"
                          class="mr-1"></CallIcon>
                        <span class="text-truncate pl-2 text-caption">
                          {{ getRepresentativeName(supervisor) }}
                        </span>
                      </div>
                    </div> -->
                  </div>
                </template>
                <UserInfoTooltip :person="getPersonByUuid(supervisor)"></UserInfoTooltip>
              </v-tooltip>
            </div>
            </div>
            <!-- Assistants -->
            <div v-if="hoverNodeId === node.id" class="d-flex align-content-end align-end" >
              <v-card v-for="(assistant, index) in getPersonAssistantsByUuids(
                node.supervisors,
                node.assistants
              )" :key="(node.id || node.uuid) + 'assistant' + assistant" class="mr-2 cardAssistants" :width="showUserPicture? '75': '140'"
                :height="showUserPicture? '70': '30%'" flat>
                <div class="align-content-center position-relative h-100" :style="getBorderCard([], assistant, index)">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props }">
                      <div :class="showUserPicture ? 'dotsPositionAssistants bg-white':'dotsPositionNoPicture'" class="py-2 ">
                      <ButtonsFrontCard style="justify-items: end;" :componentName="'fullOrga'" :isOrga="true" :person="getPersonByUuid(assistant)" :uuid="assistant"
                        :isRegularGrid="true" @click.stop.prevent />
                      </div>
                        <!-- :height="node.supervisors.length > 2 ? '44' : '88'" -->
                      <v-img  v-if="showUserPicture"  height="30" width="35" v-bind="props"
                        :src="getAvatarForUuid(assistant)" class="align-end ma-auto mt-2"
                        cover loading="lazy"
                        :lazy-src="getDefaultPicture">
                      </v-img>
                      <div class="align-center d-flex">
                        <div class="flex-column d-flex" style="width: 85%;">
                          <div :class="showUserPicture ? 'text-caption text-truncate' : 'text-body-1 text-truncate'" class="ml-1 text-left userNameContainer text--black">
                            {{ getTitelByUuid(assistant) }}
                            {{ getNameByUuid(assistant) }}
                          </div>
                        </div>
                      </div>
                      <!-- <div class="align-center d-flex flex-row justify-space-between"
                        v-if="hasRepresentative(assistant)"
                        :style="getBorderByStatusFull(getRepresentativeByUuid(assistant))">

                        <div class="d-flex flex-row align-center" style="max-width: 85%">
                          <CallIcon v-if="!disableCallBtn(getRepresentativeByUuid(assistant))"
                            :person="getRepresentativeByUuid(assistant)" :isRegularGrid="true" :ownUUID="ownUUID"
                            class="mr-1"></CallIcon>
                          <span class="text-truncate pl-2 text-caption">
                            {{ getRepresentativeName(assistant) }}
                          </span>
                        </div>
                      </div> -->
                    </template>
                    <UserInfoTooltip :person="getPersonByUuid(assistant)"></UserInfoTooltip>
                  </v-tooltip>
                </div>
              </v-card>
            </div>
            <!-- End Assistants -->
          </div>
        </div>
        <div :id="`vertical-line-node-${node.id}`"  v-if="isChildren" class="line vertical-line-node"></div>
        <div v-if="node.children && node.children.length" class="children-container">
          <div :id="`vertical-line-${node.id}`" class="line vertical-line"></div>
          <div :data-node-id="node.id" :style="{ width: calculateHorizontalLineWidth(node.children, node).width, left: calculateHorizontalLineWidth(node.children, node).left }" v-if="node.children.length>1" class="horizontal-line"></div>
          <div class="tree-row">
            <fullOrganigram :isChildren="true" :items="node.children" :zoomLevel="currentZoomLevel" />
          </div>
        </div>
      </div>
    </div>
    <div :class="showSidebar ? 'zoom-controls-sidebar' : 'zoom-controls-no-sidebar'" v-if="!isChildren">
      <div :class="isDarkMode ? 'd-flex flex-column text-center bg-custom-black' : 'd-flex flex-column text-center bg-white'">
        <v-btn-group variant="outlined" divided>
          <v-btn @click="zoomIn" icon="mdi-magnify-plus-outline"></v-btn>
          <v-btn @click="zoomOut" icon="mdi-magnify-minus-outline"></v-btn>
        </v-btn-group>
        <span>{{ defaultZoomLevel }}%</span>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../../store";
import {
  getBorderByStatusFull,
  getCustomBorderByStatusFull
} from "../../../utils/basicFunctions";
import UserInfoTooltip from "../userInfoTooltip.vue";
import ButtonsFrontCard from "../buttonsFrontCard.vue";
import { hasRepresentative } from "../../../utils/representatives";
import { disableCallBtn } from "../../../utils/calls";
import CallIcon from "../callIcon.vue";

export default {
  name: "fullOrganigram",
  directives: {},
  components: {
    UserInfoTooltip,
    ButtonsFrontCard,
    CallIcon
  },
  props: [
    "items",
    "isChildren",
    "zoomLevel"
  ],
  data() {
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      defaultZoomLevel: 100,
      isLading: true,
      hoverNodeId: null
    };
  },
  watch: {
    defaultZoomLevel(newZoomLevel) {
      this.updateHorizontalLineWidths(); // Call the function to update the width of the lines
    }
  },
  mounted() {
  },
  beforeUnmount() {
  },
  unmounted() {
  },
  methods: {
    getImageSize(type, supervisors){
      switch (supervisors) {
        case 1:
          if(type === 'maxWidth' ||type === 'width'){
            return '60';
          }
          if(type === 'height'){
            return '60';
          }
          break;
        case 2:
        if(type === 'maxWidth' ||type === 'width'){
            return '40';
          }
          if(type === 'height'){
            return '60';
          }
          break;
        case 3:
        if(type === 'maxWidth' ||type === 'width'){
            return '30';
          }
          if(type === 'height'){
            return '37';
          }
          break;
        case 4:
        if(type === 'maxWidth' ||type === 'width'){
            return '20';
          }
          if(type === 'height'){
            return '24';
          }
          break;

        default:
          break;
      }
    },
    onNodeMouseEnter(nodeId) {
      this.hoverNodeId = nodeId;
    },
    onNodeMouseLeave() {
      this.hoverNodeId = null;
    },
    getPersonAssistantsByUuids(uuids, assistantsData) {
      let uniqueAssistants = new Set(assistantsData || []);
      if (uuids && uuids.length) {
        for (let i = 0; i < uuids.length; i++) {
          const uuid = uuids[i];
          const person = store.state.group[uuid]?.user;

          if (person && Array.isArray(person.assistants)) {
            for (let j = 0; j < person.assistants.length; j++) {
              const assistant = person.assistants[j];
              uniqueAssistants.add(assistant);
            }
          }

        }
      }
      return Array.from(uniqueAssistants);
    },
    getPositionForUuid(uuid) {
      return store.getPositionForUuid(uuid);
    },
    getRepresentativeName(uuid) {
      return store.getUserRepresentativeName(uuid);
    },
    disableCallBtn(uuid) {
      const person = this.getPersonByUuid(uuid);
      return disableCallBtn(person);
    },
    getRepresentativeByUuid(uuid) {
      if (!uuid) return;
      const person = this.getPersonByUuid(uuid);
      if (!person) return;
      const representative = person.user?.representative?.value || '';
      return this.getPersonByUuid(representative);
    },
    hasRepresentative(uuid) {
      if (uuid === this.state.ownUUID) return false;
      return hasRepresentative(uuid);
    },
    getNameByUuid(uuid) {
      return store.getNameForUuid(uuid);
    },
    getTitelByUuid(uuid) {
      return store.getTitelForUuid(uuid);
    },
    zoomIn() {
      if (this.defaultZoomLevel < 200) { // Limitar a 200%
        this.defaultZoomLevel += 10;
      }
    },
    zoomOut() {
      if (this.defaultZoomLevel > 10) { // Limitar a 10%
        this.defaultZoomLevel -= 10;
      }
    },
    findParentNode(dataset, childNodeId) {
      for (const node of dataset) {
        if (node.children?.some(child => child.id === childNodeId)) {
          return node;
        }
        // If not found, search recursively through children
        if (node.children) {
          const foundNode = this.findParentNode(node.children, childNodeId);
          if (foundNode) return foundNode;
        }
      }
      return null; // Parent not found
    },
    getBorderByStatusFull(person) {
      try {
        return getBorderByStatusFull(person);
      } catch { }
    },
    getCustomBorderByStatusFull(person, position, removeRadius = false) {
      try {
        return getCustomBorderByStatusFull(person, position, removeRadius);
      } catch { }
    },
    getPersonByUuid(uuid) {
      const person = store.getPersonByUuid(uuid) || {};
      return person;
    },
    appearInAHigherSection(userUuid, startingNode) {
      let currentNode = startingNode;
      const dataset = JSON.parse(JSON.stringify(this.state.namespaceSettings.newOrganigram || []));
      while (true) {
        // Find parent node
        const parentNode = this.findParentNode(dataset, currentNode.id);
        // If no more parents (we reached the root), stop the loop
        if (!parentNode) break;
        // Check if userUuid is in supervisors of the parent node
        if (parentNode.supervisors.includes(userUuid)) {
          return true;
        }
        // Move up the tree
        currentNode = parentNode;
      }
      return false; // User is not a supervisor at a higher level than the starting node
    },
    getAvatarForUuid(uuid) {
      return store.getAvatarForUuid(uuid);
    },
    getBorderCard(supervisorArray, uuid, index) {
      return `${this.getBorderByStatusFull(this.getPersonByUuid(uuid))};  border-radius: 5px 5px 5px 5px!important;`;

    },
    getBorderStyleSupervisor(supervisors, index) {
      if(supervisors.length > 1){
        return 'containerImg100Multiple';
      }else {
        if(this.showUserPicture){
          return "containerImg100";
        } else {
          return "containerImg50";
        }
      }
    },
    calculateHorizontalLineWidth(children, parentNode) {
      // If there are no children or less than 2, return a minimum width
      if (!children || children.length < 2) return { width: "40px", left: "50%" };

      // Retrieve references to relevant HTML elements
      const parent = document.getElementById(`tree-node-${parentNode.id}`);
      const firstChild = document.getElementById(`tree-node-${children[0].id}`);
      const lastChild = document.getElementById(`tree-node-${children[children.length - 1].id}`);
      const firstVerticalLineNode = document.getElementById(`vertical-line-node-${children[0].id}`);
      const lastVerticalLineNode = document.getElementById(`vertical-line-node-${children[children.length - 1].id}`);
      const lastVerticalLine = document.getElementById(`vertical-line-${children[children.length - 1].id}`);
      // Get the current zoom level of the container (ensure it's converted to a floating-point number)
      const zoomLevel = parseFloat(this.currentZoomLevel / 100);

      // Check if all the required nodes exist
      if (parent && firstChild && lastChild && firstVerticalLineNode && lastVerticalLineNode) {
        // Get dimensions and positions of the elements
        const parentRect = parent.getBoundingClientRect();
        const firstChildRect = firstChild.getBoundingClientRect();
        const firstVerticalLineNodeRect = firstVerticalLineNode.getBoundingClientRect();
        const lastChildRect = lastChild.getBoundingClientRect();
        const lastVerticalLineNodeRect = lastVerticalLineNode.getBoundingClientRect();
        const lastVerticalLineRect = lastVerticalLine?.getBoundingClientRect(); // Optional chaining

        // Calculate the adjusted width between the first and last children, considering zoom
        const adjustedWidthBetweenChildren = (lastChildRect.right - firstChildRect.left) / zoomLevel;

        // Calculate the adjusted width of the parent node, considering zoom
        const adjustedParentWidth = parentRect.width / zoomLevel;

        // Calculate the adjusted width limited by the vertical lines, considering zoom
        const adjustedWidthLimitByVerticalLines = Math.round(
          (lastVerticalLineNodeRect.left - firstVerticalLineNodeRect.right + 1.2) / zoomLevel);

        // Determine the final adjusted width (minimum of the three constraints)
        const totalWidth = Math.min(adjustedWidthBetweenChildren, adjustedParentWidth, adjustedWidthLimitByVerticalLines);

        // Default left position is "50%" (centered relative to parent)
        let adjustedLeft = "50%";

        // If the last vertical line exists, adjust the "left" position to center it relative to this line
        if (lastVerticalLineRect) {
          const adjustedLastVerticalLineLeft = lastVerticalLineRect.left / zoomLevel;
          const adjustedParentLeft = parentRect.left / zoomLevel;

          // Calculate the left offset to center the horizontal line
          adjustedLeft = `${adjustedLastVerticalLineLeft - adjustedParentLeft - totalWidth / 2}px`;
        }else {
           // If there is no specific vertical line, use the position of the last top child.
          const adjustedLastChildLeft = (lastChildRect.left + lastChildRect.width / 2) / zoomLevel;
          const adjustedParentLeft = parentRect.left / zoomLevel;

          adjustedLeft = `${adjustedLastChildLeft - adjustedParentLeft - totalWidth / 2}px`;
        }

        // Return an object containing the calculated width and left position
        return {
          width: `${totalWidth}px`,
          left: adjustedLeft,
        };
      }
      // Default values if the required nodes are not found
      return { width: "40px", left: "50%" };
    },
    updateHorizontalLineWidths() {
      this.$nextTick(() => {
        // Recalculate the width of the horizontal lines after Vue updates the DOM
        const lines = document.querySelectorAll('.horizontal-line');  // Select all horizontal lines
        // Iterate through each horizontal line element
        lines.forEach(line => {
          // Retrieve the 'nodeId' stored in the data attribute of the line
          const nodeId = line.dataset.nodeId;
          // Find the node using the nodeId
          const node = this.findNodeById(nodeId);
          // If the node is found, calculate the horizontal line width based on the node's children and properties
          if (node) {
            const width = this.calculateHorizontalLineWidth(node.children, node).width;
            const left = this.calculateHorizontalLineWidth(node.children, node).left;
            line.style.width = width;  // Set the calculated width for the line
            line.style.left = left;  // Set the calculated width for the line
          }
        });
      });
    },
    findNodeById(id, nodes = this.items) {
      // Iterate over each node at the current level (root or child nodes)
      for (let node of nodes) {
        // If we find a node with the matching id, return it immediately
        if (node.id === id) {
          return node;
        }
        // If the node has children, perform a recursive search within the children
        if (node.children && node.children.length > 0) {
          // Recursive call to search through child nodes
          const foundNode = this.findNodeById(id, node.children);
          // If the node is found in the children, return it
          if (foundNode) {
            return foundNode;
          }
        }
      }
      // If no node is found at this level or in any child, return undefined
      return undefined;
    }
  },
  computed: {
    isDarkMode() {
      return this.state.persisted.isDark;
    },
    showUserPicture() {
      return this.currentZoomLevel>=70
    },
    currentZoomLevel() {
      return this.zoomLevel ? this.zoomLevel : this.defaultZoomLevel;
    },
    getDefaultPicture() {
      return "img/default_profile_picture.png";
    },
    showSidebar() {
      return this.state.persisted.showSidebar;
    },
  },
};
</script>

<style lang="scss" scoped>
.orgaPosition {
  position: absolute;
  left: 10px;
}

.tree-row {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 20px;
  gap: 40px;
  /* Espaciado entre nodos */
}

.top-center {
  transform-origin: top center
    /* Punto de referencia para el zoom */
}

.top-left {
  transform-origin: top left
    /* Punto de referencia para el zoom */
}

.tree-node {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.cardAssistants{
  background-color: #f9f9f9;
}
.node-content {
  // padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 275px;
  max-height: 200px;
  min-width: 275px;
  min-height: 200px;
}
.node-content-more-100{
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 200px;
  max-height: 200px;
  min-width: 200px;
  min-height: 200px;
}
.node-content-Assisntants{
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  // max-width: 120px;
  max-height: 200px;
  min-width: 275px;
  min-height: 200px;
  // padding-left: 8px;
  // padding-right: 8px;
}
.children-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.line {
  background-color: #ccc;
  position: absolute;
}

.vertical-line {
  width: 2px;
  height: 20px;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.vertical-line-node {
  width: 2px;
  height: 20px;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.horizontal-line {
  height: 2px;
  position: absolute;
  background-color: #ccc;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  transition: width 0.3s ease; /* Animación suave al cambiar */
}

.children-container>.tree-row {
  position: relative;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.zoom-controls-sidebar {
  position: fixed;
  left: 335px;
  bottom: 40px;
}

.zoom-controls-no-sidebar {
  position: fixed;
  left: 70px;
  bottom: 40px;
}

// same old orga
.containerImg50 {
  border-right: 1px solid white;
  max-width: 50%;
  width: 75px;
}

.containerImg100 {
  max-width: 100%;
  width: 150px;
  height: 145px;
}
.containerImg100Multiple{
  max-width: 100%;
  width: 150px;
  height: 100%;
}
.containerImg50{
  max-width: 100%;
  width: 150px;
  height: 50%;
}

.removeBorderRight {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-right: 0px !important;
}

.removeBorderLeft {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left: 0px !important;
}

.removeBorderBottom {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-bottom: 0px !important;
}

.removeBorderTop {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border-top: 0px !important;
}

.userNameContainer {
  width: 100%;
}

.gradientBg {
  height: 35px;
}

.v-theme--light .gradientBg {
  background: linear-gradient(#00000040, #00000099);
}
.assistantName{
  position: absolute;
  top: 0;
  left: 130px;
}
.dotsPosition{
  position: absolute;
  width: 100%;
  height: 30%;
  top: 0;
}
.dotsPositionNoPicture{
  position: absolute;
  width: 100%;
  top: 0;
}
.dotsPositionAssistants{
  position: absolute;
  width: 100%;
  height: 43px;
}
.multi-line-truncate {
  display: -webkit-box; /* Enables flexible box layout in Webkit */
  -webkit-line-clamp: 2; /* Number of lines allowed before truncating */
  -webkit-box-orient: vertical; /* Sets the box orientation to vertical */
  overflow: hidden; /* Hides the overflowed text */
  text-overflow: ellipsis; /* Adds ellipsis (...) to truncated text */
  word-wrap: break-word; /* Breaks words if necessary */
}
.heightContent {
  height: calc(100% - 38px);
}
.verticalDivider{
  display: flex;
  width: 100%;
}
.lineHeigth4user {
  line-height: 13px;
}
.lineHeigth3user {
  line-height: 19px;
}
.text--black{
  color: black;
}
.text--white{
  color: white;
}
.paddingSectionName{
  padding: 5px 10px;
  line-height: 1.2!important;
}
.gap5{
  gap: 5px;
}
.gap4{
  gap: 4px;
}
.bg-custom-black{
  background-color: #121212;
}
</style>
