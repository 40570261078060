<template>
  <v-card
    flat
    :class="{ 'pt-5': fromWhere == 'content', 'px-5': fromWhere == 'content' }"
    ref="content"
  >
    <div v-if="importError" class="floatingError">
      <v-alert type="error" :dismissible="true">{{ importError }}</v-alert>
    </div>
    <v-row class="mx-0 mb-5">
      <!-- <v-btn class="btnTab" :class="{'active': activeTab == 'overview'}" @click="setActiveTab('overview')"> {{ $t('components.excelEditor.overview') }} </v-btn> -->
      <v-btn
        class="btnTab"
        :class="{ active: activeTab == 'users' }"
        @click="setActiveTab('users')"
        >{{ $t("generics.users") }}</v-btn
      >
      <!-- <v-btn
        v-if="state.excelTotalView"
        class="btnTab"
        :class="{ active: activeTab == 'organisation' }"
        @click="setActiveTab('organisation')"
        >{{ $t("generics.organisation") }}</v-btn
      > -->
      <!-- <v-col class="text-right" style="padding: 0">
        <v-btn
          color="primary"
          v-show="activeTab == 'organisation'"
          @click="showModalOrganisationInfo = true"
          >{{ $t("generics.info") }}</v-btn
        >
      </v-col> -->
    </v-row>
    <div class="tableMenu pa-1" v-show="activeTab !== 'users'">
      <div style="display: flex; align-items: center">
        <v-tooltip location="top" v-if="activeTab == 'users'">
          <template v-slot:activator="{ props }">
            <v-btn variant="text" v-bind="props" icon  @click="addRow">
              <font-awesome-icon
                :icon="['fal', 'plus']"
                :style="{ fontSize: '18px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.excelEditor.addRow") }}</span>
        </v-tooltip>
        <v-tooltip location="top" v-if="activeTab == 'users'">
          <template v-slot:activator="{ props }">
            <v-btn variant="text" v-bind="props" icon @click="undo">
              <font-awesome-icon
                :icon="['far', 'undo']"
                :style="{ fontSize: '16px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.excelEditor.undo") }}</span>
        </v-tooltip>
        <v-tooltip location="top" v-if="activeTab == 'users'">
          <template v-slot:activator="{ props }">
            <v-btn variant="text" v-bind="props" icon @click="redo">
              <font-awesome-icon
                :icon="['far', 'redo']"
                :style="{ fontSize: '16px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.excelEditor.redo") }}</span>
        </v-tooltip>
        <div class="tableSearch ml-2">
          <input
            density="compact"
            @input="debouncedSearchFn"
            v-on:keyup.enter="search"
            ref="searchInput"
            :placeholder="$t('generics.search')"
          />
          <div class="icon" @click="search">
            <font-awesome-icon
              :icon="['far', 'search']"
              :style="{ fontSize: '15px' }"
            />
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'organisation'">
        <span class="bold"
          >{{ $t("generics.organisationChart") }}
          {{ virtualOfficePrefix }}</span
        >
      </div>
      <div>
        <v-tooltip location="top" v-if="activeTab == 'organisation'">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              icon
              @click="state.simpleOrganisationManagerVisible = true"
            >
              <font-awesome-icon
                :icon="['far', 'edit']"
                :style="{ fontSize: '16px' }"
              />
            </v-btn>
          </template>
          <span>{{
            $t("components.excelEditor.openOrganisationManager")
          }}</span>
        </v-tooltip>
        <v-tooltip location="top" v-if="activeTab == 'organisation'">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon @click="toggleCollapseAll">
              <font-awesome-icon
                :icon="['far', 'sort-alt']"
                :style="{ fontSize: '16px' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("components.excelEditor.toggleCollapseAll") }}</span>
        </v-tooltip>
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon @click="scrollRows(10)">
              <font-awesome-icon
                :icon="['far', 'chevron-down']"
                :style="{ fontSize: '16px' }"
              />
            </v-btn>
          </template>
          <span>{{
            $t("components.excelEditor.scrollDownRows", [rowsToScrollCount])
          }}</span>
        </v-tooltip>
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon @click="scrollRows(-10)">
              <font-awesome-icon
                :icon="['far', 'chevron-up']"
                :style="{ fontSize: '16px' }"
              />
            </v-btn>
          </template>
          <span>{{
            $t("components.excelEditor.scrollUpRows", [rowsToScrollCount])
          }}</span>
        </v-tooltip>
      </div>
    </div>
    <div v-show="disabledAllButtons" class="loaderContainer">
      <div class="htVisibilityParadoxContainer d-flex loaderWrapper">
        <span class="custom-loader mx-auto">
          <font-awesome-icon
            :icon="['fal', 'spinner-third']"
            class="loaderColor"
            :style="{ fontSize: '120px', width: 'auto' }"
          />
        </span>
      </div>
    </div>
    <div
      class="htVisibilityParadoxContainer"
      v-show="
        !loaded ||
        ((activeTab === 'users' || activeTab === 'organisation') &&
          !disabledAllButtons)
      "
    >
      <template v-if="activeTab === 'users'">
        <UserManagementWrapper
          :users="users.data"
          :hideSaveButtons="hideSaveButtons"
        />
      </template>
      <v-btn v-if="activeTab === 'organisation'" @click="printTable">Print Table</v-btn>
      <!-- <hot-table
        class=""
        v-show="
          !loaded || (activeTab === 'organisation' && state.excelTotalView)
        "
        :data="organisation.data"
        :settings="{ ...settings, ...organisation.settings }"
        ref="organisationTable"
        height="calc(100vh - 370px)"
      >
        <hot-column
          v-for="(column, key) in organisation.columns"
          :data="column.data"
          :key="key"
          :settings="column.settings"
        />
      </hot-table> -->
    </div>
    <v-divider class="mt-4"></v-divider>
    <div class="divButtonsExcel pb-4">
      <div class="divLeft">
        <v-menu :rounded="0" offset-y top>
          <template v-slot:activator="{ props }">
            <v-btn
              flat
              color="primary"
              class="text-white mr-2"
              v-bind="props"
              :disabled="disabledAllButtons"
            >
              {{ $t("components.excelEditor.uploadNewFile") }}
            </v-btn>
          </template>

          <v-list>
            <v-list-item link @click="$refs['uploadfileReplace'].click()">
              <v-list-item-title>{{
                $t("components.excelEditor.uploadReplace")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="$refs['uploadfileMerge'].click()">
              <v-list-item-title>{{
                $t("components.excelEditor.uploadMerge")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item @click="$refs['uploadfileDelete'].click()">
              <v-list-item-title>{{
                $t("components.excelEditor.deleteData")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- <v-btn
          v-if="activeTab === 'users'"
          color="primary"
          class="btn customButton"
          @click="showModalNote = true"
        >{{ $t('components.excelEditor.uploadNewFile') }}</v-btn>
         -->
        <input
          ref="uploadfileReplace"
          type="file"
          @change="(event) => onExcelFileChange(event, 'replace')"
          class="hidden"
          style="display: none"
        />
        <input
          ref="uploadfileMerge"
          type="file"
          @change="(event) => onExcelFileChange(event, 'merge')"
          class="hidden"
          style="display: none"
        />
        <input
          ref="uploadfileDelete"
          type="file"
          @change="(event) => deleteDataAfterConfirmation(event)"
          class="hidden"
          style="display: none"
        />
        <v-tooltip location="top" v-if="activeTab === 'users'">
          <template v-slot:activator="{ props }">
            <v-btn
              :disabled="disabledAllButtons"
              flat
              v-bind="props"
              color="primary"
              class="btn customButton"
              @click="downloadExcel()"
              >{{ $t("components.excelEditor.download") }}</v-btn
            >
          </template>
          <span>{{ $t("components.excelEditor.downloadTT") }}</span>
        </v-tooltip>
      </div>
      <div class="divRight">
        <!-- <v-btn color="primary" class="btn marginRight10 customButton" @click="getDataFromStore()">Load current state</v-btn> -->
        <v-tooltip location="top" v-if="activeTab === 'users'">
          <template v-slot:activator="{ props }">
            <v-btn
              flat
              v-bind="props"
              color="primary"
              class="btn customButton mr-2"
              :class="enabledButton ? '' : 'savingData'"
              @click="saveNewExcel()"
              :disabled="disabledAllButtons"
              >{{ $t("generics.save") }}</v-btn
            >
          </template>
          <span>{{ $t("components.excelEditor.tooltipSave") }}</span>
        </v-tooltip>
        <v-tooltip location="top" v-if="activeTab === 'users'">
          <template v-slot:activator="{ props }">
            <v-btn
              flat
              v-bind="props"
              color="primary"
              class="btn customButton mr-2"
              :class="enabledButton ? '' : 'savingData'"
              @click="saveNewExcel(true)"
              :disabled="disabledAllButtons"
              >{{ $t("generics.ok") }}</v-btn
            >
          </template>
          <span>{{ $t("generics.saveAndClose") }}</span>
        </v-tooltip>
        <v-tooltip location="top">
          <template v-slot:activator="{ props }">
            <v-btn
              flat
              v-bind="props"
              color="primary"
              class="btn customButton"
              :class="enabledButton ? '' : 'disabled'"
              :disabled="!enabledButton || disabledAllButtons"
              v-if="fromWhere == 'content'"
              @click="closeExcel()"
              >{{ $t("generics.cancel") }}</v-btn
            >
          </template>
          <span>{{ $t("components.excelEditor.tooltipClose") }}</span>
        </v-tooltip>
      </div>
    </div>
    <color-picker ref="colorPicker" />
    <helpscout-dialog ref="helpscoutDialog" />
    <v-dialog :model-value="showModalOrganisationInfo" @update:model-value="showModalOrganisationInfo = $event" persistent max-width="460">
      <v-card>
        <!-- <v-card-title class="text-h5">
          <v-img
            class="mr-1"
            src="assets/icon.png"
            max-height="30"
            max-width="30"
            contain
          ></v-img>
          {{ $t("components.excelEditor.organisationInfo0") }}
        </v-card-title> -->
        <HeaderModal
        :titleModal="$t('components.excelEditor.organisationInfo0')"
        />
        <v-card-text>
          <p>{{ $t("components.excelEditor.organisationInfo1") }}</p>
          <p>{{ $t("components.excelEditor.organisationInfo2") }}</p>
          <ul>
            <li>
              <p>{{ $t("components.excelEditor.organisationInfo2_1") }}</p>
            </li>
            <li>
              <p>{{ $t("components.excelEditor.organisationInfo2_2") }}</p>
            </li>
            <li>
              <p>{{ $t("components.excelEditor.organisationInfo2_3") }}</p>
            </li>
          </ul>
          <p>{{ $t("components.excelEditor.organisationInfo3") }}</p>
          <p>{{ $t("components.excelEditor.organisationInfo4") }}</p>
          <p>{{ $t("components.excelEditor.organisationInfo5") }}</p>
        </v-card-text>
        <!-- <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
          <v-row class="px-2 btns mx-0" flex>
            <v-col cols="8" class="px-0 py-0" align="right">
              <v-btn
                color="primary"
                @click="showModalOrganisationInfo = false"
                >{{ $t("components.excelEditor.shutDown") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <v-spacer></v-spacer> -->
        <FooterModal :showCancel="false" class="mt-4">
          <v-btn
                color="primary"
                @click="showModalOrganisationInfo = false"
                >{{ $t("components.excelEditor.shutDown") }}</v-btn
              >
        </FooterModal>
      </v-card>
    </v-dialog>
    <v-dialog :model-value="showModalNote" @update:model-value="showModalNote = $event" persistent max-width="460">
      <v-card>
        <!-- <v-card-title class="text-h5">
          <v-img
            class="mr-1"
            src="assets/icon.png"
            max-height="30"
            max-width="30"
            contain
          ></v-img>
          {{ $t("components.excelEditor.uploadNewFile") }}
        </v-card-title> -->
        <HeaderModal
        :titleModal="$t('components.excelEditor.uploadNewFile')"
        :closeModalFunction="closeModal"
        />
        <v-card-text>{{
          $t("components.excelEditor.noteNewUpload")
        }}</v-card-text>
        <!-- <v-card color="primary" class="py-2 pl-4 mt-4 pr-2 footerModal">
          <v-row class="px-2 btns mx-0" flex>
            <v-col cols="8" class="px-0 py-0" align="right">
              <v-btn color="primary" @click="showModalNote = false">{{
                $t("generics.cancel")
              }}</v-btn>
              <v-btn
                color="primary"
                @click="
                  showModalNote = false;
                  $refs['uploadfileReplace'].click();
                "
                >{{ $t("generics.accept") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <v-spacer></v-spacer> -->
        <FooterModal :closeModalFunction="showModalNote = false" class="mt-4">
          <v-btn
                color="primary"
                @click="
                  showModalNote = false;
                  $refs['uploadfileReplace'].click();
                "
                >{{ $t("generics.accept") }}</v-btn
              >
        </FooterModal>
      </v-card>
      <v-spacer></v-spacer>
    </v-dialog>
    <DeleteDataExcelModal
      :titleModal="$t('components.excelEditor.deleteData')"
      :showModal="showDeleteModal"
      :closeModal="showCloseDeleteDataModal"
      :userList="confirmDeleteUserList"
    />
  </v-card>
</template>

<script>
/* eslint-disable no-unused-vars, standard/no-callback-literal, template-curly-spacing */ /* globals Beacon */
// TODO: better error msgs for import (what team is duplicated etc)
// TODO: validator for user multiselect (supervisor/teamemmbers)
import Vue from "@vue/compat";
import moment from "../../../sharedsrc/moment";
import store, { EventBus } from "../../store";
// import { HotTable, HotColumn } from "@handsontable/vue";
import DeleteDataExcelModal from "../modal/deleteDataExcelModal.vue"
import {
  downloadExcel,
  excelToJson,
  importUsersParsed,
} from "../../lib/wsMsg";
// import Handsontable from "handsontable";
// import MultiSelectEditor from "./multiSelectEditor";
import UserSelectEditor from "./userSelectEditor";
// import tippy from "tippy.js";
import ColorPicker from "./ColorPicker.vue";
import HelpscoutDialog from "./HelpscoutDialog.vue";
import {
  // min,
  sortBy,
  includes,
  last,
  tail,
  uniq,
  // flatMap,
  // groupBy,
  debounce,
} from "lodash";
import {
  isSectionSupervisor,
  isDepartmentSupervisor,
  // initComponent,
  mergeIgnoreUndefined,
} from "./helpers";
import UserManagementWrapper from "../../components/userManagement/userManagementWrapper.vue";
import FooterModal from "../modal/modalComponents/footerModal.vue";
import HeaderModal from "../modal/modalComponents/headerModal.vue";

function isValidMD5(s) {
  return s.match(/^[a-fA-F0-9]{32}$/) !== null;
}

const UserColumns = {
  AVATAR: 0,
  TITLE: 1,
  NAME: 2,
  EMAIL: 3,
  ASSISTANTS: 4,
  CUSTOM_ID: 5,
  RMO_USER_ID: 6,
  PERMISSIONS: 7,
  POSITION: 8,
  LOCATION: 9,
  QUALIFICATION: 10,
  COMPANY: 11,
  PHONE: 12,
  EXTENSION: 13,
  MOBILE_PHONE: 14,
  DEVICE: 15,
  HOURS: 16,
  HOME_HOURS: 17,
  ACCESS_MAIL: 18,
  DASD_USER_ID: 19,
  OMK: 20,
  UUID: 21,
};

export default {
  props: ["fromWhere", "afterSaveExcel", "excelEdited"],
  components: {
    DeleteDataExcelModal,
    // HotTable,
    // HotColumn,
    ColorPicker,
    HelpscoutDialog,
    UserManagementWrapper,
    HeaderModal,
    FooterModal,
  },
  data() {
    return {
      state: store.state,
      tableWidth: "100px",
      saveShouldHide: false,
      settings: {
        licenseKey: "non-commercial-and-evaluation",
        filters: true,
        dropdownMenu: false,
        height: 100, // 'calc(100vh - 370px)',
        stretchH: "all",
      },
      users: {
        visibleRowNumber: 0,
        settings: {
          columnSorting: {
            initialConfig: {
              column: 2,
              sortOrder: "asc",
            },
          },
          fixedColumnsLeft: 4,
          contextMenu: ["row_above", "row_below", "remove_row"],
          colHeaders: (col) => {
            switch (col) {
              case UserColumns.ASSISTANTS:
                return (
                  this.$t(`excel.${this.users.columns[col].data}`) +
                  "<i class='v-icon mdi mdi-information assistants-info theme--light'></i>"
                );
              case UserColumns.PERMISSIONS:
                return (
                  this.$t(`excel.${this.users.columns[col].data}`) +
                  "<i class='v-icon mdi mdi-information rights-info theme--light'></i>"
                );
              case UserColumns.MOBILE_PHONE:
                return (
                  this.$t(`excel.${this.users.columns[col].data}`) +
                  "<i class='v-icon mdi mdi-information mobilephone-info theme--light'></i>"
                );
              case UserColumns.DASD_USER_ID:
                return (
                  this.$t(`excel.${this.users.columns[col].data}`) +
                  "<i class='v-icon mdi mdi-information dasd-user-number-info theme--light'></i>"
                );
              default:
                return this.$t(`excel.${this.users.columns[col].data}`);
            }
          },
          hiddenColumns: {
            columns: [UserColumns.UUID],
          },
          hiddenRows: {
            rows: [],
          },
        },
        data: [],
        columns: [
          {
            data: "avatar",
            settings: {
              renderer: this.userAvatarRenderer.bind(this),
            },
          },
          { data: "titel" },
          {
            data: "name",
            settings: { className: "highlighted", required: true },
          },
          {
            data: "email",
            title: "excel.vofficeEmail",
            settings: {
              className: "highlighted",
              validator: /^[^\s",:;<>@[\]\\!#$%&~]+@[^\s",:;<>@[\]\\!#$%&~.]+\.[^\s",:;<>@[\]\\!#$%&~]+$/,
            },
          },
          {
            data: "assistants",
            settings: {
              editor: UserSelectEditor,
              selectOptions: {
                type: "multiple",
                values: this.myUsers.bind(this),
                valueFn: (group) => group.id,
                displayFn: (group) => group.user.name,
              },
              renderer: this.usersRenderer.bind(this),
            },
          },
          { data: "customId" },
          { data: "rmoUserId" },
          {
            data: "permissions",
            settings: {
              className: "htCenter",
            },
          },
          { data: "position" },
          { data: "location" },
          { data: "qualification" },
          { data: "company" },
          { data: "phone" },
          { data: "extension" },
          { data: "mobilePhone" },
          { data: "device" },
          { data: "hours" },
          { data: "homeHours" },
          { data: "accessmail" },
          { data: "dasdUserId" },
          { data: "omk" },
          { data: "uuid" },
          { data: "firstName" },
          { data: "lastName" },
          {
            data: "presentFrom",
            settings: {
              readOnly: true,
              renderer: this.dateTimeRenderer.bind(this),
            },
          },
        ],
      },
      organisation: {
        visibleRowNumber: 0,
        settings: {
          contextMenu: [],
          dataSchema: {},
          colHeaders: (col) => {
            switch (col) {
              case 2:
              case 3:
                return (
                  this.$t(`excel.${this.organisation.columns[col].data}`) +
                  "<i class='v-icon mdi mdi-sort theme--light' style='padding-left: 5px; font-size: 17px'></i>"
                );
              default:
                return this.$t(`excel.${this.organisation.columns[col].data}`);
            }
          },
          columnSorting: false,
          dropdownMenu: false,
          // observeChanges: true,
          cells: this.cellProps.bind(this),
          hiddenRows: {
            rows: [],
          },
        },
        data: [],
        columns: [
          {
            data: "supervisor",
            settings: {
              readOnly: true,
              editor: UserSelectEditor,
              selectOptions: {
                type: "single",
                values: this.myUsers.bind(this),
                valueFn: (group) => group.id,
                displayFn: (group) => group.user.name,
              },
              renderer: this.supervisorRenderer.bind(this),
            },
          },
          {
            data: "assistants",
            settings: {
              readOnly: true,
              editor: UserSelectEditor,
              selectOptions: {
                type: "multiple",
                values: this.myUsers.bind(this),
                valueFn: (group) => group.id,
                displayFn: (group) => group.user.name,
              },
              renderer: this.usersRenderer.bind(this),
            },
          },
          {
            data: "section",
            settings: {
              readOnly: true,
              validator: /^\S+/,
              renderer: this.cellContent.bind(this),
            },
          },
          {
            data: "department",
            settings: {
              readOnly: true,
              renderer: this.cellContent.bind(this),
            },
          },
          {
            data: "team",
            settings: {
              readOnly: true,
              renderer: this.cellContent.bind(this),
            },
          },
          {
            data: "teamMembers",
            settings: {
              readOnly: true,
              editor: UserSelectEditor,
              selectOptions: {
                type: "multiple",
                values: this.myUsers.bind(this),
                valueFn: (group) => group.id,
                displayFn: (group) => group.user.name,
              },
              renderer: this.usersRenderer.bind(this),
            },
          },
          {
            data: "homepage",
            settings: {
              readOnly: true,
              renderer: this.plainTextRenderer.bind(this),
            },
          },
        ],
      },
      enabledButton: true,
      importError: undefined,
      activeTab: "users",
      loaded: false,
      addedHooks: false,
      showModalOrganisationInfo: false,
      showModalNote: false,
      rowsToScrollCount: 10,
      newExcel: false,
      disabledAllButtons: false,
      showDeleteModal: false,
      confirmDeleteCallback: null,
      confirmDeleteUserList: [],
    };
  },
  methods: {
  printTable() {
    // Access the hot-table instance
    let hotInstance = this.$refs.organisationTable.hotInstance

    // Method to convert hot-table data to HTML table
    let htmlTable = this.convertHotToHTML(hotInstance);

    // Open a new window or tab for printing
    let printWindow = window.open('', '_blank');

    // Write the HTML table to the new window document
    printWindow.document.write(htmlTable);

    // Style the table in the new window if needed
    // Example: printWindow.document.write('<style>table { border-collapse: collapse; }</style>');

    // Trigger the print dialog
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  },
  convertHotToHTML(hotInstance) {
    let data = hotInstance.getData();
    let columns = hotInstance.getSettings().columns;
    let html = '<table border="1">'; // Simple border for clarity

    // Add table headers
    html += '<thead><tr>';
    columns.forEach(column => {
      html += `<th>${column.data}</th>`;
    });
    html += '</tr></thead>';

    // Add table body
    html += '<tbody>';
    data.forEach(row => {
    html += '<tr>';
    row.forEach(cell => {
        // Initialize namesList here if you need to build it throughout the iteration
        let namesList = '';
        // Check if the cell is neither null nor undefined
        if (cell != null) {
          if (Array.isArray(cell)) {
            // Use map to create an array of names and then join with commas
            namesList = cell.map(cellData => {
              const name = store.getNameForUuid(cellData);
              return name; // Return the name to be added to the array
            }).join(','); // Join all names with a comma
          } else {
            namesList = cell;
            // console.log('myVariable is not an array.');
          }
        }
        // Add each cell's data to HTML, handling the case where namesList is empty
        html += `<td>${namesList.length > 0 ? namesList : ''}</td>`;
      });
      html += '</tr>';
    });
    html += '</tbody>';

    // Close the table tag
    html += '</table>';

    return html;
  },
    showCloseDeleteDataModal(result){
      this.showDeleteModal = !this.showDeleteModal;
      if (this.confirmDeleteCallback) {
        this.confirmDeleteCallback(result);
        this.confirmDeleteCallback = null;
        this.confirmDeleteUserList = [];
      }
    },
    deleteDataAfterConfirmation(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      const reader = new FileReader();
      reader.onerror = (err) => {
        this.importError = err.message;
        this.cleanFileInputs();
      };
      reader.onload = async (e) => {
        const excel = await excelToJson(e.target.result);
        this.disabledAllButtons = true;
        const { user } = excel;
        this.importError = undefined;
        const promise = new Promise((resolve) => (this.confirmDeleteCallback = resolve));
        this.confirmDeleteUserList = user;
        this.showDeleteModal = true;
        const result = await promise;
        if (result === "accept") {
          this.enabledButton = false;
          try {
            await importUsersParsed({ user }, { overwrite: true, update: false, delete: true });
            try {
              const audioPath = '/media/notificationSound.mp3';
              // Get real devices id's
              const ringingDeviceId = store.state.persisted.mediaDeviceSetup.ringingOutputId;
              const audioDeviceId = store.state.persisted.mediaDeviceSetup.audioOutputId;
              // Pre-Set outputs
              const audioOutput = new Audio(audioPath);
              const ringingOutput = new Audio(audioPath);
              // Sync audioDevice
              let promise = Promise.resolve();
              if ('sinkId' in audioOutput && 'setSinkId' in audioOutput && audioDeviceId) {
                promise = audioOutput.setSinkId(audioDeviceId);
              }
              await promise
                .then(() => audioOutput.play())
                .catch(err => console.warn('excel(importUsersParsed) Failed to play notification audio on audioOutput', err));
              // Sync && Play at ringing device only if we have ringingDeviceId for it and if it's different from audioOutputId
              if (audioDeviceId && ringingDeviceId && 'sinkId' in ringingOutput && 'setSinkId' in ringingOutput && ringingDeviceId !== audioDeviceId) {
                promise = ringingOutput.setSinkId(ringingDeviceId);
                await promise
                  .then(() => ringingOutput.play())
                  .catch(err => console.warn('excel(importUsersParsed) Failed to play notification audio on ringingOutput', err));
              }
            } catch (error) {
              console.warn("excel(importUsersParsed) Failed to play notification audio", error);
            }
            this.calculateHiddenRows();
          } catch (err) {
            console.warn("deleteDataAfterConfirmation Error:", err);
            this.importError = err.message;
          }
          this.enabledButton = true;
        }
        this.cleanFileInputs();
      };
      reader.readAsDataURL(files[0]);
    },
    cleanFileInputs() {
      // function to clean file inputs and resets the onchange event
      if (this.$refs.uploadfileReplace) {
        this.$refs.uploadfileReplace.value = "";
      }
      if (this.$refs.uploadfileMerge) {
        this.$refs.uploadfileMerge.value = "";
      }
      if (this.$refs.uploadfileDelete) {
        this.$refs.uploadfileDelete.value = "";
      }
      this.disabledAllButtons = false;
    },
    hideSaveButtons(boolVal) {
      this.saveShouldHide = boolVal;
    },
    myUsers() {
      return sortBy(
        Object.entries(this.state.group)
          .filter(([uuid, group]) => !group.user.guest && !group.user.visitor)
          .map(([uuid, group]) => {
            group.id = uuid;
            return group;
          }),
        [(group) => group.user.name]
      );
    },
    search() {
      const query = this.$refs.searchInput.value;
      var searchPlugin = this.activeTable.hotInstance.getPlugin("search");
      const hideRowsPlugin =
        this.activeTable.hotInstance.getPlugin("hiddenRows");
      const result = searchPlugin.query(query);
      const allRows = this.activeData.data.map((e, i) => i);
      const rowsToHide = uniq(result.map((result) => result.row));
      if (query) {
        hideRowsPlugin.hideRows(allRows);
        hideRowsPlugin.showRows(rowsToHide);
      } else {
        hideRowsPlugin.showRows(allRows);
        if (this.activeTab == "organisation") this.calculateHiddenRows();
      }
      this.activeTable.hotInstance.render();
    },
    toggleCollapse(field) {
      if (store.state.user.collapsed[field].length === 0) {
        const allFields = uniq(this.organisation.data.map((row) => row[field]));
        store.state.user.collapsed[field] = allFields;
      } else {
        store.state.user.collapsed[field] = [];
      }
    },
    toggleCollapseAll() {
      this.toggleCollapse("section");
      this.toggleCollapse("department");
    },
    calculateHiddenRows() {
      const rowsToHide = [];
      store.state.user.collapsed.section.forEach((section) => {
        const rowIndexes = tail(
          this.organisation.data.reduce(
            (result, org, index) =>
              org.section === section ? [...result, index] : result,
            []
          )
        );
        rowsToHide.push(...rowIndexes);
      });
      store.state.user.collapsed.department.forEach((department) => {
        const rowIndexes = tail(
          this.organisation.data.reduce(
            (result, org, index) =>
              org.department === department ? [...result, index] : result,
            []
          )
        );
        rowsToHide.push(...rowIndexes);
      });
      this.organisation.settings.hiddenRows.rows = rowsToHide;

      if (this.$refs.organisationTable) {
        const plugin =
          this.$refs.organisationTable.hotInstance.getPlugin("hiddenRows");
        plugin.updatePlugin();
        this.$refs.organisationTable.hotInstance.render();
      }
    },
    showHelpscoutDialog(key, selection, clickEvent) {
      const dataRow = this.organisation.data[selection[0].start.row];
      const column = this.organisation.columns[selection[0].start.col].data;
      this.$refs.helpscoutDialog.show(dataRow, (helpscoutBeaconId) => {
        dataRow.helpscoutBeaconId = helpscoutBeaconId;
      });
    },
    showColorPicker(key, selection, clickEvent) {
      const dataRow = this.organisation.data[selection[0].start.row];
      const column = this.organisation.columns[selection[0].start.col].data;
      const selector = `#cell_${selection[0].start.row}_${column}`;
      this.$refs.colorPicker.showPicker(selector, (color) => {
        dataRow.color = color.hex;
      });
    },
    userAvatarRenderer(instance, td, row, col, prop, value, cellProperties) {
      // handsontable contextmenu bug: the contextmenu only works for the first layer of elements
      // e.g.:
      // <div> // works
      //   <div></div> // doesn't work
      // </div>
      // workround: first layer element (full-size absolute position)
      td.style = "position: relative;";
      const avatarWidget = `<div style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"></div><div style="display: flex; padding: 4px 0;">
          <img src="${
            value ? value : "assets/default_profile_picture.png"
          }" style="height: 50px; border-radius: 5px;">
      </div>`;

      if (td.innerHTML !== avatarWidget) {
        td.innerHTML = avatarWidget;
      }

      td.className = cellProperties.valid === false ? "error" : "";

      return td;
    },
    supervisorRenderer(instance, td, row, col, prop, value, cellProperties) {
      const group = this.state.group[value];
      const displayName = [
        group && group.user && group.user.titel,
        group && group.user && group.user.name,
      ]
        .filter(Boolean)
        .join(" ");
      // handsontable contextmenu bug: the contextmenu only works for the first layer of elements
      // e.g.:
      // <div> // works
      //   <div></div> // doesn't work
      // </div>
      // workround: first layer element (full-size absolute position)
      td.style = "position: relative;";
      const avatarWidget = `<div style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"></div><div style="display: flex; padding: 4px 0;">
          <img src="${
            group && group.user && group.user.avatar
              ? group.user.avatar
              : "assets/default_profile_picture.png"
          }" style="height: 50px; border-radius: 5px;">
          <div style="padding-left: 4px">${displayName}</div>
      </div>`;

      if (td.innerHTML !== avatarWidget) {
        td.innerHTML = avatarWidget;
      }

      td.className = cellProperties.valid === false ? "error" : "highlighted";

      return td;
    },
    plainTextRenderer(instance, td, row, col, prop, value, cellProperties) {
      td.innerHTML = value;
      return td;
    },
    usersRenderer(instance, td, row, col, prop, value, cellProperties) {
      if (Array.isArray(value)) {
        td.innerHTML = value
          .map((uuid) => store.getPersonByUuid(uuid))
          .filter((group) => group && group.user != null)
          .map((group) => group.user.name)
          .join(", ");
      } else {
        const user = store.getPersonByUuid(value);
        td.innerHTML = user ? user.user.name : "";
      }
      td.className = cellProperties.valid === false ? "error" : "";
      return td;
    },
    dateTimeRenderer(instance, td, row, col, prop, value, cellProperties) {
      td.innerHTML = value ? moment(value).format("LLLL") : "";
      return td;
    },
    cellContent(instance, td, row, col, prop, value, cellProperties) {
      const dataRow = this.organisation.data[row];
      const isSupervisor =
        prop === "section"
          ? isSectionSupervisor(dataRow)
          : isDepartmentSupervisor(dataRow);
      const rowIndexes = tail(
        this.organisation.data.reduce(
          (result, org, index) =>
            org[prop] === value ? [...result, index] : result,
          []
        )
      );
      const isCollapsed = includes(store.state.user.collapsed[prop], value);
      td.style = "position: relative;";

      let content;
      if (value) {
        content = `<div style="position: absolute; top: 0; right: 0; bottom: 0; left: 0"></div><div class="${
          isSupervisor || prop === "team" ? "" : "color-transparent"
        }" style="display: flex; align-items: center; padding-left: 4px; height: 100%">
          <div class="structure-name"><i class='v-icon mdi ${
            isCollapsed ? "mdi-chevron-right" : "mdi-chevron-down"
          } ${
          !isSupervisor || !rowIndexes.length ? "color-transparent" : ""
        }' style='font-size: 16px'></i>${value}</div>
          <div class="chat-icon ${
            dataRow.helpscoutBeaconId ? "" : "color-transparent"
          }"><i class='v-icon mdi mdi-chat'></i></div>
        </div>`;
      } else {
        content = "";
      }

      td.innerHTML = content;
      td.style.backgroundColor = value ? dataRow.color : "";
      td.id = `cell_${row}_${prop}`;
      td.className = cellProperties.valid === false ? "error" : "";
      const emptyRow =
        !dataRow.supervisor && !dataRow.department && !dataRow.team;
      if (emptyRow && includes(["section", "department", "team"], prop))
        td.className = "attention";

      const structureNameElement =
        td.getElementsByClassName("structure-name")[0];
      if (value && structureNameElement && isSupervisor) {
        structureNameElement.addEventListener("click", (event) => {
          const collapsed = this.state.user.collapsed[prop];
          const index = collapsed.indexOf(value);
          if (index !== -1) {
            collapsed.splice(index, 1);
          } else {
            collapsed.push(value);
          }
        });
      }

      const chatElement = td.getElementsByClassName("chat-icon")[0];
      if (chatElement && dataRow.helpscoutBeaconId) {
        chatElement.addEventListener("click", (event) => {
          Beacon("destroy");
          Beacon("init", dataRow.helpscoutBeaconId);
          Beacon("config", {
            display: {
              text: dataRow[prop],
            },
          });
          Beacon("open");
        });
      }

      return td;
    },
    isContextColorChangeVisible() {
      const lastSelectedColumn =
        this.$refs.organisationTable.hotInstance.getSelectedLast()[1];
      const column = this.organisation.columns[lastSelectedColumn].data;
      return column !== "section" && column !== "department";
    },
    isContextHelpscoutVisible() {
      const lastSelectedColumn =
        this.$refs.organisationTable.hotInstance.getSelectedLast()[1];
      const column = this.organisation.columns[lastSelectedColumn].data;
      return !includes(["section", "department", "team"], column);
    },
    calculateRights() {
      const sections = {};
      this.organisation.data.forEach((row, index) => {
        // set section rights
        if (!row.section || sections[row.section] != null) {
          row.sectionRights = undefined;
        } else {
          row.sectionRights = 1;
          sections[row.section] = {
            color: row.color,
            departments: {},
          };
        }
        // set department rights
        if (
          !row.section ||
          !row.department ||
          (sections[row.section] &&
            sections[row.section].departments[row.department] != null)
        ) {
          row.departmentRights = undefined;
        } else {
          row.departmentRights = 1;
          sections[row.section].departments[row.department] = {
            color: row.color,
            teams: {},
          };
        }
        // set default values for non
        if (!!row.section || !!row.department || !!row.team) {
          if (!row.section) row.section = last(Object.keys(sections));
          if (!!row.section && !row.department)
            row.department = last(
              Object.keys(sections[row.section].departments)
            );
        }
        // set colors
        const section = sections[row.section];
        const department =
          sections[row.section] &&
          sections[row.section].departments[row.department];
        row.color =
          department && department.color
            ? department.color
            : section && section.color
            ? section.color
            : undefined;
      });
    },
    cellProps: function (row, col) {
      const currentColumn = this.organisation.columns[col].data;
      const currentRow = this.organisation.data[row];
      const cellProps = {};
      switch (currentColumn) {
        case "assistants":
          cellProps.readOnly =
            !isSectionSupervisor(currentRow) &&
            !isDepartmentSupervisor(currentRow);
          break;
        case "section":
          cellProps.readOnly =
            !isSectionSupervisor(currentRow) &&
            currentRow &&
            currentRow.section != null;
          break;
        case "department":
          cellProps.readOnly =
            (currentRow && currentRow.team) ||
            isSectionSupervisor(currentRow) ||
            (!isDepartmentSupervisor(currentRow) &&
              currentRow &&
              currentRow.department != null) ||
            (currentRow && currentRow.section == "all");
          break;
        case "team":
          cellProps.readOnly =
            currentRow &&
            currentRow.section != "all" &&
            (isSectionSupervisor(currentRow) ||
              isDepartmentSupervisor(currentRow));
          break;
        case "homepage":
        case "teamMembers":
          cellProps.readOnly =
            (currentRow &&
              !currentRow.section &&
              !currentRow.department &&
              !currentRow.team) ||
            (currentRow &&
              currentRow.sectionRights == 1 &&
              currentRow.section != "all");
          break;
      }
      cellProps.readOnly = true;
      return cellProps;
    },
    addRow() {
      this.activeTable.hotInstance.alter("insert_row", 0);
      this.activeTable.hotInstance.scrollViewportTo(0);
      this.activeTable.hotInstance.selectCell(0, 0);
    },
    undo() {
      this.activeTable.hotInstance.undo();
    },
    redo() {
      this.activeTable.hotInstance.redo();
    },
    scrollRows(count) {
      const nextRowNumber = this.activeData.visibleRowNumber + count;
      if (nextRowNumber < 0) {
        this.activeData.visibleRowNumber = 0;
      } else if (nextRowNumber > this.activeData.data.length - 1) {
        this.activeData.visibleRowNumber = this.activeData.data.length - 1;
      } else {
        this.activeData.visibleRowNumber = nextRowNumber;
      }
      this.activeTable.hotInstance.scrollViewportTo(
        this.activeData.visibleRowNumber
      );
    },
    setActiveTab(title, resolve = null) {
      this.activeTab = title;
      this.$refs.searchInput.value = "";

      setTimeout(() => {
        if (!this.activeData.data.length) {
          this.activeTable?.hotInstance.alter("insert_row");
        }
        this.activeTable?.hotInstance.scrollViewportTo(
          this.activeData.visibleRowNumber
        );
        this.activeTable?.hotInstance.render();
        const component = this;
        var plugin = this.activeTable?.hotInstance.getPlugin("AutoRowSize");
        this.activeTable?.hotInstance.updateSettings({
          afterScrollVertically() {
            component[title].visibleRowNumber = plugin.getFirstVisibleRow();
          },
        });
        this.calculateHiddenRows();

        if (resolve) {
          resolve();
        }
      }, 250);
      this.activeTable?.hotInstance.render();
    },
    getDataFromStore() {
      this.users.data.splice(0, this.users.data.length);
      this.organisation.data.splice(0, this.organisation.data.length);
      const groupieToLine = (uuid, g) => {
        const l = [];
        this.users.columns.forEach((key) => {
          if (key.data === "uuid") {
            l.push(uuid);
          } else if (key.data === "permissions") {
            l.push(g[key.data]);
          } else {
            l.push(g.user[key.data]);
          }
        });
        return l;
      };

      for (let uuid in this.state.group) {
        // if (!isValidMD5(uuid)) continue; // Skip non-md5
        if (!this.state.group[uuid].user || !this.state.group[uuid].user.name)
          continue; // Skip anonymous
        if (
          this.state.group[uuid].permissions === 10 ||
          this.state.group[uuid].user.guest
        ) {
          continue;
        }
        if (this.state.group[uuid].user.assistants == null) {
          this.state.group[uuid].user.assistants = [];
        }
        this.users.data.push(groupieToLine(uuid, this.state.group[uuid]));
      }
      this.users.data = sortBy(this.users.data, [1]);

      const normalize = (value) => (value ? value.toLowerCase() : "");

      this.organisation.data = sortBy(
        Array.isArray(this.orgFromState) ? this.orgFromState : [],
        [
          (org) => normalize(org.section),
          (org) => normalize(org.department),
          (org) => normalize(org.team),
        ]
      );

      this.renderComplete();
    },
    getDataFromFileReplace(files) {
      files = files || this.state.excelFile;
      if (!files || !files.length) {
        return;
      }
      this.users.data.splice(0, this.users.data.length);
      const reader = new FileReader();
      reader.onload = async (e) => {
        const excel = await excelToJson(e.target.result);

        const objectToLineUser = (o) => {
          const l = [];
          this.users.columns.forEach((k) => l.push(o[k.data]));
          return l;
        };
        excel.user.forEach((element) => {
          this.users.data.push(objectToLineUser(element));
        });
        this.renderComplete();
      };
      reader.readAsDataURL(files[0]);
    },
    getDataFromFileMerge(files) {
      files = files || this.state.excelFile;
      if (!files || !files.length) {
        return;
      }
      const reader = new FileReader();
      reader.onload = async (e) => {
        const excel = await excelToJson(e.target.result);

        const objectToLineUser = (o) => {
          const l = [];
          this.users.columns.forEach((k) => l.push(o[k.data]));
          return l;
        };

        excel.user.forEach((element) => {
          const excelUser = objectToLineUser(element);
          const existingUser = this.users.data.find(
            (existingUser) =>
              existingUser[UserColumns.EMAIL] == excelUser[UserColumns.EMAIL]
          );

          if (existingUser) {
            mergeIgnoreUndefined(existingUser, excelUser);
          } else {
            this.users.data.push(excelUser);
          }
        });

        this.renderComplete();
      };
      reader.readAsDataURL(files[0]);
    },
    async downloadExcel() {
      const excel = await downloadExcel();
      if (excel && excel.data) {
        const blob = new Blob([Uint8Array.from(excel.data)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = window.location.hostname + ".xlsx";
        link.click();
      }
    },
    async saveUserChanges(closeAfterSave = false, userObject) {
      console.log("User Object ", userObject);
    },
    async saveNewExcel(closeAfterSave = false) {
      if (!this.newExcel) {
        EventBus.$emit("saveUserMgmtData", closeAfterSave);
      } else {
        this.disabledAllButtons = true;
        this.newExcel = false;
        this.enabledButton = false;
        this.importError = undefined;
        const data = { user: [], organisation: [] };
        /*
        if (this.$refs.organisationTable.hotInstance.countEmptyRows()) {
          this.organisation.data = this.organisation.data.filter((line) => {
            return (
              (Array.isArray(line) && !!line.filter((v) => !!v).length) ||
              (!Array.isArray(line) && Object.values(line).some(Boolean))
            );
          });
          this.$refs.organisationTable.hotInstance.render();
          await new Promise((resolve) => setTimeout(resolve, 100));
        }

        const organisationValidationResult = await new Promise((resolve) => {
          this.$refs.organisationTable.hotInstance.validateCells((valid) =>
            resolve(valid)
          );
        });

        if (!organisationValidationResult) {
          this.enabledButton = true;
          this.importError = "Validation failed in organisation table.";
          return;
        }
        */
        this.users.data.forEach((line) => {
          const u = {};
          let valid = false;
          this.users.columns.forEach((key, index) => {
            if (key.data === "permissions") {
              u[key.data] = parseInt(line[index], 10) || 0;
            } else {
              if (key.data === "email" && line[index]) valid = true;
              u[key.data] = line[index];
            }
          });
          if (valid) data.user.push(u);
        });

        // const usersByName = groupBy(data.user, "name");
        // const usersById = groupBy(data.user, "uuid");

        data.organisation = this.organisation.data;
        if (this.state.excelFile) this.state.excelFile = undefined;

        try {
          await importUsersParsed(data, { overwrite: true, update: true });
          if (this.fromWhere == "setup") {
            this.afterSaveExcel();
          } else if (this.fromWhere == "content") {
            // this.excelEdited();
          }
          this.enabledButton = true;
          try {
            const audio = new Audio("/media/notificationSound.mp3");
            await audio.play();
            if (closeAfterSave) this.$router.push({ path: "/organisation" });
          } catch (error) {
            console.warn("Failed to play notification audio", error);
          }
        } catch (err) {
          console.warn("importUsersParsed Error:", err);
          this.enabledButton = true;
          this.importError = err.message;
        }
        this.calculateHiddenRows();
        this.cleanFileInputs();
      }
    },
    closeExcel() {
      this.excelEdited();
    },
    onExcelFileChange(event, mode) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;
      if (mode === "replace") {
        this.getDataFromFileReplace(files);
      } else if (mode === "merge") {
        this.getDataFromFileMerge(files);
      }
      this.newExcel = true;
      this.cleanFileInputs();
    },
    getAllUsers() {
      if (!this.users || !this.users.data) return [];
      return this.users.data.map((user) => user[1]); // Double check when changing columns order!
    },
    getSortedAllUsers() {
      return this.getAllUsers().sort();
    },
    renderComplete() {
      // if (!this.addedHooks) {
      //   this.$refs.organisationTable.hotInstance.addHook(
      //     "afterOnCellMouseUp",
      //     (event, coords) => {
      //       if (coords.row === -1 && coords.col === 3) {
      //         this.toggleCollapse("department");
      //       } else if (coords.row === -1 && coords.col === 2) {
      //         this.toggleCollapse("section");
      //       }
      //     }
      //   );
      //   this.$refs.organisationTable.hotInstance.addHook(
      //     "afterChange",
      //     (changes, action) => {
      //       this.calculateRights();
      //       if (action === "edit") {
      //         const [rowIndex, column, oldValue, newValue] = changes[0];
      //         const row = this.organisation.data[rowIndex];

      //         if (oldValue && column === "section") {
      //           this.organisation.data.forEach((currentRow) => {
      //             if (currentRow.section === oldValue)
      //               currentRow.section = newValue;
      //           });
      //           const indexOfOldValue =
      //             store.state.user.collapsed.section.indexOf(oldValue);
      //           if (indexOfOldValue != -1)
      //             Vue.set(
      //               store.state.user.collapsed.section,
      //               indexOfOldValue,
      //               newValue
      //             );
      //         }

      //         if (oldValue && column === "department") {
      //           this.organisation.data.forEach((currentRow) => {
      //             if (
      //               currentRow.section === row.section &&
      //               currentRow.department === oldValue
      //             )
      //               currentRow.department = newValue;
      //           });
      //           const indexOfOldValue =
      //             store.state.user.collapsed.department.indexOf(oldValue);
      //           if (indexOfOldValue != -1)
      //             Vue.set(
      //               store.state.user.collapsed.department,
      //               indexOfOldValue,
      //               newValue
      //             );
      //         }

      //         if (column === "department" && !oldValue && !row.supervisor) {
      //           row.supervisor =
      //             this.calculatedStructure[row.section].supervisor;
      //         }

      //         if (column === "team" && !oldValue && !row.supervisor) {
      //           const section = this.calculatedStructure[row.section];
      //           const department = section
      //             ? section.departments[row.department]
      //             : undefined;
      //           row.supervisor = department
      //             ? department.supervisor
      //             : section
      //             ? section.supervisor
      //             : undefined;
      //         }
      //       }
      //       this.calculateRights();
      //       this.calculateHiddenRows();
      //       this.$refs.organisationTable.hotInstance.validateCells();
      //       this.$refs.organisationTable.hotInstance.render();
      //     }
      //   );
      //   this.$refs.organisationTable.hotInstance.addHook(
      //     "afterCreateRow",
      //     (...args) => {
      //       this.$refs.organisationTable.hotInstance.validateRows([args[0]]);
      //     }
      //   );
      //   this.$refs.organisationTable.hotInstance.addHook(
      //     "afterRemoveRow",
      //     (...args) => {
      //       this.calculateRights();
      //       this.$refs.organisationTable.hotInstance.validateCells();
      //     }
      //   );
      //   this.addedHooks = true;
      // }
      this.loaded = true;
      if (
        this.state.excelTotalView === "organisation" &&
        this.activeTab !== "organisation"
      ) {
        this.setActiveTab("organisation");
      }
    },
    validatorNamesExist(value, callback) {
      if (value && value.match(/.+/)) {
        const values = value.split(",");
        const names = this.getAllUsers();
        for (let name of values) {
          if (names.indexOf(name) === -1) {
            return callback(false);
          }
        }
        return callback(true);
      } else {
        return callback(false);
      }
    },
    validatorAssistance(value, callback) {
      if (!value || value.length === 0) return callback(true);
      const values = value.split(",");
      if (values.length > 3) return callback(false);
      const names = this.getAllUsers();
      for (let name of values) {
        if (names.indexOf(name) === -1) {
          return callback(false);
        }
      }
      return callback(true);
    },
    validatorNamesExistAllowEmpty(value, callback) {
      if (value && value.match(/.+/)) {
        const values = value.split(",");
        const names = this.getAllUsers();
        for (let name of values) {
          if (names.indexOf(name) === -1) {
            return callback(false);
          }
        }
        return callback(true);
      } else {
        return callback(true);
      }
    },
    validatorOneNameExists(value, callback) {
      if (value && value.match(/.+/)) {
        const names = this.getAllUsers();
        if (names.indexOf(value) !== -1) {
          return callback(true);
        }
      }
      return callback(false);
    },
  },
  computed: {
    saveButtonsActive() {
      return this.saveShouldHide;
    },
    debouncedSearchFn() {
      return debounce(this.search, 250);
    },
    virtualOfficePrefix() {
      return (
        this.state.namespaceSettings.companyInfo.virtualOfficePrefix ||
        store.getVirtualOfficePrefix()
      );
    },
    orgFromState() {
      return (this.state.namespaceSettings || {}).organisation || [];
    },
    activeTable() {
      return this.$refs[`${this.activeTab}Table`];
    },
    activeData() {
      return this[this.activeTab];
    },
    calculatedStructure() {
      const sections = {};
      this.organisation.data.forEach((row, index) => {
        // set section rights
        if (!row.section || sections[row.section] != null) {
          row.sectionRights = undefined;
        } else {
          row.sectionRights = 1;
          sections[row.section] = {
            supervisor: row.supervisor,
            color: row.color,
            departments: {},
          };
        }
        // set department rights
        if (
          !row.section ||
          !row.department ||
          (sections[row.section] &&
            sections[row.section].departments[row.department] != null)
        ) {
          row.departmentRights = undefined;
        } else {
          row.departmentRights = 1;
          sections[row.section].departments[row.department] = {
            supervisor: row.supervisor,
            color: row.color,
            teams: {},
          };
        }
      });
      return sections;
    },
  },
  mounted: function mounted() {
    this.setActiveTab("users");
    if (this.state.excelFile) {
      this.getDataFromFileReplace();
    } else {
      this.getDataFromStore();
    }
  },
  watch: {
    "state.user.collapsed": {
      immediate: true,
      deep: true,
      handler() {
        this.calculateHiddenRows();
      },
    },
    "state.wsStatus": {
      handler(status) {
        switch (status) {
          case "Identity rejected":
            this.importError = "unknown identity";
            break;
          case "Secret rejected":
            this.importError = "invalid secret";
            break;
        }
      },
    },
    "state.excelTotalView": {
      handler: function (value) {
        if (!value) {
          this.setActiveTab("users");
        } else if (value === "organisation") {
          this.setActiveTab("organisation");
        }
      },
    },
    "state.excelFile": {
      handler: function (value) {
        this.getDataFromFileReplace();
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&subset=cyrillic-ext");
@import "~handsontable/dist/handsontable.full.css";
@import "~tippy.js/dist/tippy.css";

.loaderColor {
  color: var(--v-primary-base);
}
.loaderWrapper {
  position: relative;
  top: 30%;
}
.loaderContainer {
  // height: calc(100vh - 370px);
  height: 80%;
}
.custom-loader {
  animation: loader 1s infinite;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.d-none {
  display: none;
}
.floatingError {
  position: absolute;
  left: 8;
  right: 8;
  z-index: 1000;
}

:deep() .colHeader {
  font-weight: bold;
  font-size: 15px;
}

.tabs {
  min-height: 430px;
}
.divButtonsExcel {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  .divLeft {
    width: 50%;
    text-align: left;
  }
  .divRight {
    width: 50%;
    text-align: right;
  }
  .customButton {
    min-width: 46px !important;
    &:hover {
      opacity: 0.8;
    }
    &:active,
    &:focus {
      box-shadow: none;
      color: white;
    }
  }
}

.tableMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--v-primary-base);
  border: 1px solid var(--v-primary-darken1);
  border-bottom: 0;
  width: calc(100% - 1px);

  .v-btn,
  span {
    color: #ffffff !important;
  }
}

.htVisibilityParadoxContainer :deep() {
  .handsontable .htDimmed {
    background-color: rgb(248, 248, 248);
  }

  .rights-info {
    margin-left: 8px;
    font-size: 19px;
  }
  .assistants-info {
    margin-left: 8px;
    font-size: 19px;
  }

  .colHeader:hover {
    text-decoration: none !important;
  }

  // no official api to set custom header classes
  // https://github.com/handsontable/handsontable/issues/5977
  th:nth-child(1),
  th:nth-child(6) {
    button.changeType {
      display: none;
    }
  }

  td {
    font-size: 15px !important;
    &.highlighted {
      background-color: #ffffcc;
      color: black;
    }
    &.attention {
      background-color: #ffaf49;
      color: black;
    }
    &.error {
      background-color: #ff4c42;
      color: black;
    }
  }
}

.btnTab {
  background-color: transparent !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  color: #2a3133 !important;
  &.active {
    border-bottom: 2px solid #2a3133;
  }
}
</style>

<style lang="scss">

.v-theme--dark  .handsontable td {
  background-color: #1e1e1e;
  color: #fff;
}
.v-theme--dark  .handsontable th {
  background-color: #1e1e1e;
  color: #fff;
}
.v-theme--dark  .handsontable th.ht__highlight  {
  background-color: #131313;
  color: #fff;
}
.v-theme--dark  .handsontable th .v-icon{
  color: #fff;
}
.tableSearch {
  display: flex;
  align-items: center;

  input {
    /*height: 20px;*/
    min-width: 160px;
    background-color: white;
    border-color: #ffffff;
    border-radius: 2px 0 0 2px;
    /*margin-left: 8px;*/
    padding-left: 8px;

    &:focus {
      outline: none;
    }
  }

  .icon {
    cursor: pointer;
    color: var(--v-primary-base);
    padding-top: 1px;
    padding-right: 5px;
    background-color: #ffffff;
    height: 24px;
    border-radius: 0 2px 2px 0;
  }
}

td .mdi-chat {
  font-size: 16px !important;
  padding-left: 8px;
  color: transparent;
}

td:hover .mdi-chat {
  color: black;
}

td:hover .color-transparent .mdi-chat {
  color: transparent;
}

.is-paddingless {
  padding: 0 !important;
}

.bold {
  font-weight: bold;
}

.color-transparent {
  color: transparent !important;
}

.closePermanent {
  cursor: pointer;
  float: right;
}
.htCustomEditor {
  color: black;
  background: #fff;
  padding: 5px 7px;
  position: absolute;
  z-index: 9001;
  /*
   * This hack enables to change <select> dimensions in WebKit browsers
   */
  -webkit-appearance: menulist-button !important;
}
/* .htVisibilityParadoxContainer {
  display: block;
  min-height: 300px;
  overflow: auto;
  max-height: calc(100vh - 330px);
} */

td {
  max-width: 250px !important;
}

.savingData {
  animation: blink 1s linear infinite;
  pointer-events: none;
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>