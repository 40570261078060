<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <!-- <v-timeline v-if="messages.length > 0">
    <v-timeline-item
      small
      v-for="(message, name, index) in messages"
      :key="index"
      fill-dot
      :right="message.creatorUUID === ownUUID"
      :left="message.creatorUUID !== ownUUID"
      :color="message.creatorUUID === ownUUID ? 'primary': 'teal lighten-2'"
    >
      <v-card>
        <v-card-subtitle class="py-0">
          <v-row align="center" justify="start">
            <span class="caption font-weight-bold">{{getCreatorTitel(message.creatorUUID)}} {{getCreatorName(message.creatorUUID)}}</span>
          </v-row>
        </v-card-subtitle>
        <v-container class="py-0">
          <v-row>

            <v-col v-if="message.isHtml" class="py-0" cols="12"><span v-html="$sanitize(message.body)"></span></v-col>
            <v-col v-else class="py-0" cols="12">{{message.body}}</v-col>

          </v-row>
        </v-container>
        <v-card-subtitle class="py-0">
          <v-row align="center" justify="end">
            <span class="caption">{{dateFormatted(message.date)}}</span>
          </v-row>
        </v-card-subtitle>
      </v-card>
    </v-timeline-item>
  </v-timeline> -->
  <v-row>
    <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
      <v-card flat class="d-flex flex-column fill-height">
        <v-card-text class="flex-grow-1">
          <template v-for="(message, name, index) in messages" :key="index">
            <div
              :class="{
                'd-flex flex-row-reverse': message.creatorUUID === ownUUID,
              }"
            >
              <v-card
                :color="message.creatorUUID === ownUUID ? isDark ? 'primary' : '#fff' : '#bbc9cc4d'"
                style="
                  height: auto;
                  word-break: break-word;
                  max-width: 85%;
                "
                class="pa-3 mb-2 messageBorders"
                :class="isDark? 'msgCardDark': 'msgCard'"
                label
              >
                <div style="white-space: pre-line;">
                  <span v-html="$sanitize(checkForUrls(getMessageBody(message)))"></span>
                </div>
                <v-row class="py-3">
                  <v-col cols="8" v-if="showBtnConferences(message)" class="pb-0">
                      <v-btn v-if="getStatusConference(message) === 'pending'" class="mr-1" color="primary" @click="acceptConference(message)">{{$t('components.meetingLineModal.accept')}}</v-btn>
                      <v-btn v-if="getStatusConference(message) === 'pending'" color="primary" @click="cancelConference(message)">{{$t('components.meetingLineModal.reject')}}</v-btn>
                      <p v-if="getStatusConference(message) === 'accepted' || getStatusConference(message) === 'rejected'">{{getStatusConference(message) === 'accepted' ? $t('components.conferenceForm.acceptedConference') : $t('components.conferenceForm.rejectedConference')}}</p>
                  </v-col>
                  <v-col :cols="showBtnConferences(message)? '4': '12'" class="text-right align-self-end">
                      <span class="text-caption">{{ dateFormatted(message.creationServerTs || message.date) }}</span>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { useStore } from "effector-vue/composition";
import moment from "../../../sharedsrc/moment";
import store from "../../store";
import { timelineEvents, dispatchRsvpTimelineEntry } from "../../effector/timeline";
import {checkForUrls} from "../../utils/basicFunctions"

export default {
  components: {},
  props: ["messages"],
  data() {
    const effector = {
      cacheTimeline: timelineEvents,
    };
    Object.entries(effector).forEach(([key, effectorStore]) => {
      effector[key] = useStore(effectorStore);
    });
    return {
      state: store.state,
      ownUUID: store.state.ownUUID,
      moment,
      // Effector
      ...effector,
    };
  },
  computed: {
    isDark() {
      return store.state.persisted.isDark;
    },
  },
  methods: {
    checkForUrls(message){
      return checkForUrls(message, false)
    },
    getMessageBody(message){
      if(this.isATranslateMessage(message)){
        const keyTranslate = message.header.translateMessage.translateKey;
        let params = message.header.translateMessage.params || null;
        let translate = params ? this.$t(keyTranslate,params) : this.$t(keyTranslate);
        return translate
      }else{
        return message.body
      }
    },
    showBtnConferences(message){
      if(message.type === 'plannerEvent' && message.header && message.header.typeMsg === 'newConference' && message.creatorUUID !== this.state.ownUUID){
        return true
      }else{
        return false
      }
    },
    isATranslateMessage(message){
     if(message.header && message.header.translateMessage  && message.header.translateMessage.translateKey){
        return true
      }else{
        return false
      }
    },
    getStatusConference(message) {
      let conferenceId = null;
       if(message.type === 'plannerEvent' && message.header && message.header.typeMsg === 'newConference' && message.header.meetingId){
          conferenceId = message.header.meetingId
      }
      if(!conferenceId) return;
      const dataConference = this.cacheTimeline.find(
        (e) => e.uuid === conferenceId
      );
      if(dataConference && dataConference.rsvp && dataConference.rsvp[this.ownUUID] && dataConference.rsvp[this.ownUUID].status){
        return dataConference.rsvp[this.ownUUID].status;
      }else {
        return 'error'
      }
    },
    acceptConference(message){
      dispatchRsvpTimelineEntry([message.header.meetingId, true, this.$t('components.meetingLineModal.accept')]);
    },
    cancelConference(message){
      dispatchRsvpTimelineEntry([message.header.meetingId, false, this.$t('components.conferenceForm.defaultMessageToDecline')]);
    },
    getCreatorName(uuid) {
      return store.getNameForUuid(uuid);
    },
    getCreatorTitel(uuid) {
      return store.getTitelForUuid(uuid);
    },
    dateFormatted(date) {
      return this.moment(new Date(date)).format("DD.MM.YY, HH:mm");
    },
  },
};
</script>
<style lang="scss">
.msgCardDark a {
  color: white!important;
}
.messageBorders{
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  border-radius: 4px !important;
}
</style>

