<template>
  <v-container fluid class="p-0 fill-height">
    <SubdomainAvailable v-if="!isAllowed" :isAllowed="isAllowed" />
    <template v-if="isAllowed">
      <div class="p-0 fill-height fill-width">
        <v-row class="p-0 m-0 fill-height" v-if="state.setUpConfig.route !== 'paypal'">
          <v-col
            cols="6"
            class="bg-primary backgroundImage hidden-sm-and-down"
            :md="6"
            :xl="6"
          >
            <div class="fill-half-width divContentText">
              <div class="logoLeftsideDiv">
                <v-img
                  src="assets/icon.png"
                  max-height="150"
                  max-width="150"
                  contain
                ></v-img>
              </div>
              <h2 class="font-weight-bold mb-2">{{ getUrl }}</h2>
              <p class="mb-0 customFontSize font-weight-medium">
                {{
                  isFree
                    ? $t("components.companyRegister.domainIsFree")
                    : $t("components.subdomainTaken.namespaceNotFree")
                }}
              </p>
            </div>
            <div class="copyRightDiv d-flex">
              RA-MICRO Software AG © 2024
              <v-menu offset-y>
                <template v-slot:activator="{ props }">
                  <v-img
                    class="ml-2 cursorPointer"
                    :src="`img/flags/${getSelectedLang}.png`"
                    :width="23"
                    aspect-ratio="4/3"
                    cover
                    v-bind="props"
                  />
                </template>
                <v-list>
                  <v-list-item
                    v-for="(lang, index) in getLangs"
                    :key="index"
                    @click="changeLang(lang.name)"
                  >
                    <v-list-item-title class="text-center">
                      <v-img
                      :width="23"
                      aspect-ratio="4/3"
                      cover
                      :src="lang.img" />
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <span
                class="ml-2"
                @click.stop
                v-html="$t('components.takeAction.privacyPolicylegalNotive')"
              ></span>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="formRightSide"
            :md="6"
            :sm="12"
            :xs="12"
            :xl="6"
          >
            <div class="contentRightSide">
              <v-row flex class="w-100 mx-0 my-0 justify-space-between">
                <v-col class="px-0 py-0" align="left">
                  <v-card-title
                    :class="{
                      'titleSize d-flex px-0 mb-0':
                        state.setUpConfig.route === 'subdomainAvailable',
                    }"
                  >
                    <span
                      class="font-weight-medium"
                      v-html="$t('components.companyRegister.getStarted')"
                    ></span>
                  </v-card-title>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pt-4">
                <v-col class="mt-4">
                  <CompanyRegister
                    :setIsFree="setIsFree"
                    :setNamespaceName="setNamespaceName"
                    v-if="state.setUpConfig.route === 'subdomainAvailable'"
                  />
                  <Conditions v-if="state.setUpConfig.route === 'conditions'" />
                  <Plans v-if="state.setUpConfig.route == 'plans'" />
                  <VerifyEmail v-if="state.setUpConfig.route == 'verifyEmail'" />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <PayPal
          :isFromRegister="true"
          :namespaceName="namespaceName"
          :isFree="isFree"
          v-if="state.setUpConfig.route == 'paypal'"
        />
      </div>
    </template>
  </v-container>
</template>

<script>
import { webLicensedBaseFeatures } from "../../../sharedsrc/licensedFeatures";
import store from "../../store";
import SubdomainAvailable from "./subdomainAvailable.vue";
import Conditions from "./conditions.vue";
import Plans from "./plans.vue";
import VerifyEmail from "./verifyEmailRegister.vue";
import PayPal from "./paypal.vue";
import CompanyRegister from "./companyRegister.vue";
/* eslint-disable no-useless-return */
export default {
  components: {
    SubdomainAvailable,
    CompanyRegister,
    Conditions,
    Plans,
    PayPal,
    VerifyEmail
  },
  data() {
    return {
      state: store.state,
      setSetUpConfigRoute: store.setSetUpConfigRoute,
      namespaceName: `${document.location.protocol}//${document.location.host}`,
      isFree: true,
      languages: [
        { name: "DE", img: "img/flags/DE.png" },
        { name: "EN", img: "img/flags/EN.png" },
        { name: "ES", img: "img/flags/ES.png" },
      ],
    };
  },
  created() {
    if (process.env.PKG) {
      // Skip licensing redirect on pkg
    } else {
      /* if (window.location.search === "?utm_source=wp") {
        this.setSetUpConfigRoute("configuration");
      } */
      if (webLicensedBaseFeatures.isDev) return; // Skip licensing redirect on dev domains
      if (webLicensedBaseFeatures.isSeCustomer) return; // Skip licensing redirect on *.es.voffice.pro
    }
  },
  computed: {
    getSelectedLang() {
      return (this.state.user.language || this.$locale.current()).toUpperCase();
    },
    getLangs() {
      return this.languages;
    },
    getUrl() {
      return this.namespaceName; //window.location.href;
    },
    isAllowed() {
      return webLicensedBaseFeatures.isAllowed;
    },
  },
  methods: {
    changeLang(lang) {
      this.selectedLang = lang;
      setTimeout(() => {
        this.$locale.change(lang.toLowerCase());
        this.$set(this.state.user, "language", this.$locale.current());
      }, 300);
    },
    setNamespaceName(value = false) {
      if (value) {
        this.namespaceName = value;
      }
    },
    setIsFree(value) {
      this.isFree = value;
    },
  },
};
</script>
<style scoped lang="scss">
.cursorPointer {
  cursor: pointer;
}
.logoLeftsideDiv {
  width: 155px;
  background: white;
  border-radius: 50%;
  height: 155px;
  padding: 3px;
  margin-bottom: 20px;
}
.titleSize {
  font-size: 18px;
}
.copyRightDiv {
  position: absolute;
  bottom: 20;
  left: 30px;
  font-size: 13px;
}
.customFontSize {
  font-size: 17px;
}
.fill-half-width {
  width: 100%;
}
.fill-width {
  width: 100%;
}
.fill-height {
  height: 100%;
}
.p-0 {
  padding: 0 !important;
}
.m-0{
  margin: 0 !important;
}
.contentRightSide {
  display: table-cell;
  vertical-align: middle;
}
.formRightSide {
  position: relative;
  width: 50%;
  display: table;
  padding-left: 5%;
  padding-right: 5%;
  height: 100%;
}
.backgroundImage {
  color: white;
  display: table;
  height: 100%;
  padding-left: 6%;
  padding-right: 6%;
  .divContentText {
    display: table-cell;
    vertical-align: middle;
  }
}
</style>
